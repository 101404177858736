/**
 * External Dependencies
 */
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {
	CircularProgress,
} from '@material-ui/core';

/**
 * Internal Dependencies
 */
import api, { isLoggedIn } from './lib/api';
import Router from './Router';
import Login from './pages/Login';
import PasswordReset from './pages/PasswordReset';
import Signup from './pages/Signup';

export default class App extends Component {
	state = {
		organization: {},
		loading: true,
	};

	componentDidMount() {
		const organization = JSON.parse( window.sessionStorage.getItem( 'dinamicoOrganization' ) );
		if ( organization && organization.id ) {
			this.loadOrganization( organization.id );
		} else {
			api( '/organizations' )
				.then( response => {
					if ( !response.ok ) {
						return;
					}

					return response.json();
				} )
				.then( organizations => organizations.data )
				.then( organizations => {
					this.loadOrganization( organizations[0].id );
				} );
		}
	}

	loadOrganization( id ) {
		this.setState( { loading: true } );

		return api( `/organizations/${id}` )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( organization => {
				if ( !organization ) {
					return;
				}

				window.sessionStorage.setItem( 'dinamicoOrganization', JSON.stringify( organization ) );
				this.setState( { organization, loading: false } );
			} );
	}

	handleOrganizationChange( e ) {
		this.loadOrganization( e.target.value );
	}

	logout() {
		window.localStorage.removeItem( 'dinamicoLogin' );
		window.location.replace( '/login' );
		api( '/auth/token', 'DELETE' );
	}

	render() {
		const { classes } = this.props;

		return <Suspense fallback={<CircularProgress />} >
			<BrowserRouter>
				<Routes>
					<Route
						path="/login"
						element={
							isLoggedIn
								? <Navigate
									replace
									to={{
										pathname: '/',
									}}
								/>
								: <Login />
						}
					/>

					<Route
						path="/password-reset"
						element={
							isLoggedIn
								? <Navigate
									replace
									to={{
										pathname: '/settings',
									}}
								/>
								: <PasswordReset/>
						}
					/>

					<Route
						path="/signup"
						element={
							isLoggedIn
								? <Navigate
									replace
									to={{
										pathname: '/',
									}}
								/>
								: <Signup/>
						}
					/>

					<Route
						path="*"
						element={
							!isLoggedIn &&
							window.location.pathname !== '/login'
								? <Navigate
									replace
									to={{
										pathname: '/login',
										search: window.location.pathname !== '/' && `?redirect=${window.location.pathname}`,
									}}
								/>
								: <Router classes={classes} loading={this.state.loading} organization={this.state.organization || {}} loadOrganization={this.loadOrganization.bind( this )} logout={this.logout.bind( this )} />
						}
					/>
				</Routes>
			</BrowserRouter>
		</Suspense>;
	}
}

App.propTypes = {
	classes: PropTypes.object.isRequired,
};
