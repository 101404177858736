/**
 * External Dependencies
 */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
	Box,
	Container,
	Snackbar,
	Slide,

	Link,
} from '@material-ui/core';
import {
	Alert,
} from '@material-ui/lab';

/**
 * Internal Dependencies
 */
import Logo from '../dinamico.svg';
import api from '../lib/api';
import LoginForm from '../components/Login/LoginForm';
import TOTPForm from '../components/Login/TOTPForm';

const styles = theme => ( {
	root: {
		maxWidth: '380px',
		textAlign: 'center',
	},
	form: {
		backgroundColor: '#fff',
		borderRadius: '5px',
		padding: theme.spacing( 3 ),
		margin: theme.spacing( 5, 0, 2, 0 ),
	},
	innerBox: {
		padding: theme.spacing( 1 ),
		textAlign: 'start',
	},
	logo: {
		width: '100%',
	},
	textField: {
		marginTop: theme.spacing( 3 ),
	},
	footer: {
		'& span:not(:last-child)::after': {
			content: '"\\00B7"',
			padding: theme.spacing( 1 ),
		},
	},
} );

class Login extends Component {
	state = {
		error: '',
		showerror: false,
		secondFactor: false,
		recaptchaToken: '',
	};

	emailRef = createRef();
	passwordRef = createRef();
	tokenRef = createRef();

	handleLogin( e ) {
		e.preventDefault();

		if ( this.state.secondFactor ) {
			api( '/auth/totp?auth=cookie', 'POST', {
				token: this.tokenRef.value,
			} )
				.then( response => response.json() )
				.then( user => {
					if ( !user.token ) {
						this.showError( user.message || 'Error logging in, please try again' );
						return;
					}

					window.sessionStorage.removeItem( 'dinamicoOrganization' );
					window.localStorage.setItem( 'dinamicoIsLoggedIn', true );

					const params = new URLSearchParams( window.location.search );
					const redirect = params.get( 'redirect' );
					window.location.replace( redirect || '/' );
				} );
		} else {
			if ( !this.emailRef.value || !this.passwordRef.value ) {
				this.showError( 'Email and Password are required to log in' );
				return;
			}

			if ( !this.state.recaptchaToken ) {
				this.showError( 'Unknown error. Please refresh and try again.' );
				return;
			}

			api( '/auth/token?auth=cookie', 'POST', {
				email: this.emailRef.value,
				password: this.passwordRef.value,
				token: this.state.recaptchaToken,
			} )
				.then( response => response.json() )
				.then( user => {
					if ( !user.token ) {
						this.showError( user.message || 'Error logging in, please try again' );
						return;
					}

					if ( !user.authorized ) {
						this.setState( { secondFactor: true } );
						return;
					}

					window.sessionStorage.removeItem( 'dinamicoOrganization' );
					window.localStorage.setItem( 'dinamicoIsLoggedIn', true );

					const params = new URLSearchParams( window.location.search );
					const redirect = params.get( 'redirect' );
					window.location.replace( redirect || '/' );
				} )
				.catch( _ => {
					this.showError( 'Unknown error, please try again' );
				} );
		}
	}

	setRecaptchaToken( recaptchaToken ) {
		this.setState( { recaptchaToken } );
	}

	showError( error ) {
		this.setState( { error, showerror: true } );
	}

	hideError() {
		this.setState( { showerror: false } );
	}

	render() {
		const { classes } = this.props;
		return <Box className={ classes.innerBox }>
			<Container maxWidth="sm" elevation="16" className={ classes.root }>
				<Container maxWidth="sm" elevation="16" className={ classes.form }>
					<Snackbar
						open={this.state.showerror}
						onClose={this.hideError.bind( this )}
						TransitionComponent={Slide}
					>
						<Alert severity="error" variant="filled">
							{this.state.error}
						</Alert>
					</Snackbar>
					<img className={ classes.logo } src={Logo} />
					{ this.state.secondFactor
						? <TOTPForm tokenRef={ ref => ( this.tokenRef = ref ) } onSubmit={ this.handleLogin.bind( this ) } />
						: <LoginForm
							emailRef={ ref => ( this.emailRef = ref ) }
							passwordRef={ ref => ( this.passwordRef = ref ) }
							onSubmit={ this.handleLogin.bind( this ) }
							setRecaptchaToken={ this.setRecaptchaToken.bind( this ) }
						/>
					}
				</Container>
				<div className={ classes.footer }>
					<span><Link to={ '/signup' } component={ RouterLink }>Sign Up</Link></span>
					<span><Link to={ '/password-reset' } component={ RouterLink }>Forgot Password?</Link></span>
				</div>
			</Container>
		</Box>;
	}
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Login );
