/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isEmail } from 'validator';
import QRCode from 'qrcode';
import {
	Typography,
	Button,
	TextField,
	Snackbar,
	Slide,
} from '@material-ui/core';
import {
	Alert,
} from '@material-ui/lab';

/**
 * Internal Dependencies
 */
import api from '../lib/api';

// Code splitting this large library
const PasswordStrengthBar = React.lazy( () => import( 'react-password-strength-bar' ) );

const styles = theme => ( {
	root: {
		'& .MuiTextField-root': {
			margin: theme.spacing( 1 ),
			width: 400,
		},
	},
	textField: {
		marginTop: theme.spacing( 3 ),
	},
	passwordStrength: {
		width: 400,
		margin: theme.spacing( 0, 1 ),
	},
} );

class Settings extends Component {
	state = {
		name: '',
		email: '',
		password: '',
		verifyPassword: '',
		is2FA: false,

		the2FASecret: '',
		the2FAQRCode: '',
		confirm2FA: false,
		confirmationToken: '',

		error: '',
		showError: false,
	};

	componentDidMount() {
		api( '/me' )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( user => {
				if ( !user ) {
					return;
				}

				this.setState( user );
			} );
	}

	handleChange( name ) {
		return event => {
			this.setState( {
				[name]: event.target.value,
			} );
		};
	}

	handleSubmit( event ) {
		event.preventDefault();

		if ( this.state.password !== this.state.verifyPassword ) {
			this.showError( 'Passwords don\'t match' );
			return;
		}

		if ( !this.validateEmail() ) {
			this.showError( 'Invalid email address' );
			return;
		}

		const data = {
			name: this.state.name,
			email: this.state.email,
		};

		if ( this.state.password.length > 0 &&
			this.state.password === this.state.verifyPassword ) {
			data.password = this.state.password;
		}

		api( '/me', 'PATCH', data )
			.then( response => {
				if ( response.ok ) {
					window.location.reload();
					return;
				}

				return response.json();
			} )
			.then( error => {
				if ( !error ) {
					return;
				}

				this.showError( error.message );
			} );
	}

	enable2FA() {
		api( '/me/totp', 'POST' )
			.then( response => response.json() )
			.then( async secret => {
				this.setState( {
					confirm2FA: true,
					the2FASecret: secret.secret,
					the2FAQRCode: await QRCode.toDataURL( secret.url ),
				} );
			} );
	}

	confirm2FA( e ) {
		e.preventDefault();

		api( '/me/totp/confirm', 'POST', {
			token: this.state.confirmationToken,
		}, { autoLogout: false } )
			.then( response => {
				if ( !response.ok ) {
					this.showError( 'Invalid token' );
					return;
				}

				window.location.reload();
			} );
	}

	showError( error ) {
		this.setState( { error, showerror: true } );
	}

	hideError() {
		this.setState( { showerror: false } );
	}

	validateEmail() {
		return isEmail( this.state.email );
	}

	validatePasswordError() {
		return this.state.verifyPassword.length === 0 ||
			this.state.password === this.state.verifyPassword;
	}

	render() {
		const { classes } = this.props;
		return <React.Fragment>
			<Typography component="h1" variant="h1">
				Settings
			</Typography>

			<form className={ classes.root } onSubmit={ this.handleSubmit.bind( this ) }>
				<TextField onChange={ this.handleChange( 'name' ) } className={ classes.textField } id="name" variant="outlined" size="small" label="Name" value={this.state.name} />
				<br />
				<TextField onChange={ this.handleChange( 'email' ) } error={ !this.validateEmail() } className={ classes.textField } id="email" variant="outlined" size="small" label="Email" value={this.state.email} />
				<br />
				<TextField onChange={ this.handleChange( 'password' ) } className={ classes.textField } id="password" variant="outlined" size="small" label="Change Password" type="password" />
				<PasswordStrengthBar password={ this.state.password } className={ classes.passwordStrength } />
				<br />
				<TextField onChange={ this.handleChange( 'verifyPassword' ) } error={ !this.validatePasswordError() } className={ classes.textField } id="confirmPassword" variant="outlined" size="small" label="Confirm Password" type="password" />
				<br />
				<Button type="submit" className={ classes.textField } variant="contained" color="primary">
					Save
				</Button>
			</form>

			<Typography component="h2" variant="h2">
				Two Factor Authentication
			</Typography>

			{ this.state.confirm2FA
				? <React.Fragment>
					<form onSubmit={ this.confirm2FA.bind( this ) }>
						<img src={ this.state.the2FAQRCode } />
						<br />
						<TextField className={ classes.textField } onChange={ this.handleChange( 'confirmationToken' ) } id="totp" variant="outlined" label="Token" />
						<br />
						<Button type="submit" className={ classes.textField } variant="contained" color="primary">
							Save
						</Button>
					</form>
				</React.Fragment>
				: <React.Fragment>
					{ this.state.is2FA &&
						<Typography>Two Factor Authentication is enabled</Typography>
					}
					<Button onClick={ this.enable2FA.bind( this ) } className={ classes.textField } variant="contained" color="primary">
						{
							this.state.is2FA
								? 'Update 2FA'
								: 'Enable 2FA'
						}
					</Button>
				</React.Fragment>
			}

			<Snackbar
				open={this.state.showerror}
				onClose={this.hideError.bind( this )}
				TransitionComponent={Slide}
			>
				<Alert severity="error" variant="filled">
					{this.state.error}
				</Alert>
			</Snackbar>
		</React.Fragment>;
	}
}

Settings.propTypes = {
	classes: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
};

export default withStyles( styles )( Settings );
