import 'whatwg-fetch';

let API_URL = 'https://api.dinamicosystems.com';
if ( process.env.NODE_ENV && process.env.NODE_ENV !== 'production' ) {
	API_URL = 'http://localhost:3100';
}

export const READ = 1 << 0;
export const WRITE = 1 << 1;
export const EDIT = 1 << 2;
export const DELETE = 1 << 3;

export const isLoggedIn = window.localStorage.getItem( 'dinamicoIsLoggedIn' );

let globalPermissions, organizationPermissions;
if ( isLoggedIn ) {
	const getPermissions = () => {
		Fetch( '/me' )
			.then( async response => {
				if ( !response.ok ) {
					return;
				}

				const me = await response.json();
				globalPermissions = me.globalPermissions;
				organizationPermissions = me.organizationPermissions;
			} );

		// Poll for new permissions every so often
		// This won't get you new permissions immediately,
		// but if you just leave the app open in your browser
		// it should help ensure your permissions aren't super stale.
		setTimeout( getPermissions, 3600 * 1000 );
	};
	getPermissions();
}

const currentUserHasGlobalPermissions = ( resource, permissions = READ ) => {
	permissions = stringToPermission( permissions );

	const cap = globalPermissions?.find( cap => cap.resource === resource );
	if ( !cap ) {
		return false;
	}

	return cap.permissions & permissions;
};

const currentUserHasOrganizationPermissions = ( resource, orgId, permissions = READ ) => {
	permissions = stringToPermission( permissions );

	if ( !orgId ) {
		return false;
	}
	const org = organizationPermissions?.find( organization => organization.organizationId === orgId );
	if ( !org ) {
		return false;
	}

	const cap = org.permissions?.find( cap => cap.resource === resource );
	if ( !cap ) {
		return false;
	}

	return cap.permissions & permissions;
};

export const currentUserCanListOrgs = () => {
	if ( !isLoggedIn ) {
		return false;
	}

	if ( currentUserHasGlobalPermissions( 'organization' ) ) {
		return true;
	}

	if ( organizationPermissions?.length > 1 ) {
		return true;
	}

	return false;
};
export const currentUserCan = ( resource, orgId, permissions = READ ) => {
	permissions = stringToPermission( permissions );

	if ( !isLoggedIn ) {
		return false;
	}

	if ( currentUserHasGlobalPermissions( resource, permissions ) ) {
		return true;
	}

	if ( currentUserHasOrganizationPermissions( resource, orgId, permissions ) ) {
		return true;
	}

	return false;
};

function stringToPermission( permission ) {
	switch ( permission ) {
	case 'read':
		return READ;

	case 'write':
		return WRITE;

	case 'edit':
		return EDIT;

	case 'delete':
		return DELETE;
	}

	return permission;
}

export default function Fetch( url, method = 'GET', body ) {
	const isAuthRequest = url.substring( 0, 5 ) === '/auth';
	if ( !isAuthRequest && url.substring( 0, 3 ) !== '/v1' ) {
		url = '/v1' + url;
	}

	const options = {
		method,
		headers: {
			'Content-Type': 'application/json',
		},
	};

	if ( body ) {
		options.body = JSON.stringify( body );
	}

	if ( url.substring( 0, 1 ) === '/' ) {
		url = url.substring( 1 );
	}

	options.credentials = 'include';
	return fetch( `${API_URL}/${url}`, options )
		.then( response => {
			if ( isLoggedIn && !isAuthRequest && response.status === 401 ) {
				window.localStorage.removeItem( 'dinamicoIsLoggedIn' );
				window.location.reload();
				return;
			}

			return response;
		} );
}
