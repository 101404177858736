/**
 * External Dependencies
 */
import React, { useEffect, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Routes, Route, Link as RouterLink, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import clsx from 'clsx';

import {
	Typography,
	Link,
	AppBar,
	Toolbar,
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	CircularProgress,
} from '@material-ui/core';

import {
	AccountCircle as AccountCircleIcon,
	Business as OrganizationIcon,
	Menu as MenuIcon,
	People as EmployeesIcon,
	Settings as SettingsIcon,
	SupervisedUserCircle as SupervisedUserCircleIcon,
	Work as RoleIcon,
} from '@material-ui/icons';

/**
 * Internal Dependencies
 */
import Logo from './icon.svg';
import { currentUserCan, currentUserCanListOrgs } from './lib/api';
import Employees from './pages/Employees';
import Employee from './pages/Employee';
import Role from './pages/Role';
import Roles from './pages/Roles';
import Organizations from './pages/Organizations';
import Organization from './pages/Organization';
import Users from './pages/Users';
import Settings from './pages/Settings';

const Reports = React.lazy( () => import( './pages/Reports' ) );

const routes = org => [
	{
		to: '/',
		text: 'Organization',
		component: Organization,
		icon: OrganizationIcon,
		show: currentUserCan( 'organization', org ),
	},
	{
		to: '/organizations',
		text: 'Organizations',
		component: Organizations,
		icon: OrganizationIcon,
		show: currentUserCanListOrgs(),
		globalMenu: true,
	},
	{
		to: '/roles',
		text: 'Roles',
		component: Roles,
		icon: RoleIcon,
		show: currentUserCan( 'role', org ),
	},
	{
		to: '/roles/:roleId',
		component: Role,
		show: false,
		canView: currentUserCan( 'role', org ),
	},
	{
		to: '/roles/:roleId/reports/:report',
		component: Reports,
		show: false,
		canView: currentUserCan( 'role', org ),
	},
	{
		to: '/employees',
		text: 'Employees',
		component: Employees,
		icon: EmployeesIcon,
		show: currentUserCan( 'employee', org ),
	},
	{
		to: '/employees/:employeeId',
		component: Employee,
		show: false,
		canView: currentUserCan( 'employee', org ),
	},
	{
		to: '/users',
		text: 'Users',
		component: Users,
		icon: SupervisedUserCircleIcon,
		show: currentUserCan( 'user', org ),
	},
	{
		to: '/settings',
		text: 'Settings',
		component: Settings,
		icon: SettingsIcon,
		show: true,
	},
];

function NotFound() {
	return <>
		<Typography variant="h1">404 Not Found</Typography>
		<Typography>This is an invalid URL. Please go back and try again.</Typography>
	</>;
}

const drawerWidth = 240;

const styles = theme => ( {
	root: {
		display: 'flex',
	},
	logo: {
		width: '25px',
		verticalAlign: 'middle',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		flexGrow: 1,
	},
	title: {
		flexGrow: 1,
		margin: theme.spacing( 0, 2 ),
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create( 'width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		} ),
	},
	drawerClose: {
		transition: theme.transitions.create( 'width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		} ),
		overflowX: 'hidden',
		width: theme.spacing( 7 ) + 1,
		[theme.breakpoints.up( 'sm' )]: {
			width: theme.spacing( 9 ) + 1,
		},
	},
	content: {
		flexGrow: 1,
		position: 'relative',
		padding: theme.spacing( 0, 3 ),
	},
	toolbar: theme.mixins.toolbar,
	topbarButton: {
		fontSize: '1rem',
		'&:not(:last-child)': {
			'margin-right': '1rem',
		},
	},
	topbarButtonIcon: {
		margin: theme.spacing( 0, 1 ),
	},
} );

function Router( { classes, organization, loadOrganization, loading, logout } ) {
	const sessionStorageOrDefault = ( key, defaultValue ) => {
		const value = window.sessionStorage.getItem( key );
		if ( !value ) {
			return defaultValue;
		}

		return JSON.parse( value );
	};
	const [ open, setOpen ] = useState( sessionStorageOrDefault( 'menuopen', true ) );
	const toggleOpen = () => setOpen( !open );

	useEffect( () => {
		window.sessionStorage.setItem( 'menuopen', open );
	}, [ open ] );

	const location = useLocation();
	useEffect( () => {
		if ( process.env.NODE_ENV !== 'dev' && process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test' ) {
			ReactGA.initialize( 'UA-179507298-2' );
		}

		ReactGA.set( { dimension1: organization.id } );
		const path = window.location.pathname + window.location.search;
		ReactGA.pageview( path.replace( /\d+/, '??' ) );
	}, [ location ] );

	return <div className={ classes.root }>
		<AppBar position="fixed" className={ classes.appBar }>
			<Toolbar>
				<IconButton color="inherit" onClick={toggleOpen}>
					<MenuIcon />
				</IconButton>
				<Typography className={ classes.title } variant="h6" noWrap>
					{ organization.name } | <img className={ classes.logo } src={Logo} /> Dinamico Dashboard
				</Typography>
				<IconButton className={ classes.topbarButton } onClick={ logout } edge="start" color="inherit" aria-label="menu">
					Logout <AccountCircleIcon className={ classes.topbarButtonIcon } />
				</IconButton>
			</Toolbar>
		</AppBar>
		<Drawer
			className={ clsx( classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			} ) }
			variant="permanent"
			classes={{
				paper: clsx( classes.drawerPaper, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				} ),
			}}
		>
			<div className={ classes.toolbar } />
			<Divider />
			<List>
				{ routes( organization.id ).filter( route => route.show && !route.globalMenu ).map( ( route, index ) => (
					<Link to={ route.to } key={ index } component={ RouterLink } underline="none">
						<ListItem button>
							<ListItemIcon><route.icon /></ListItemIcon>
							<ListItemText primary={ route.text } />
						</ListItem>
					</Link>
				) ) }
			</List>

			{ routes( organization.id ).filter( route => route.show && !!route.globalMenu ).length > 0 &&
			<Divider />
			}
			<List>
				{ routes( organization.id ).filter( route => route.show && !!route.globalMenu ).map( ( route, index ) => (
					<Link to={ route.to } key={ index } component={ RouterLink } underline="none">
						<ListItem button>
							<ListItemIcon><route.icon /></ListItemIcon>
							<ListItemText primary={ route.text } />
						</ListItem>
					</Link>
				) ) }
			</List>
		</Drawer>
		<main className={ classes.content }>
			<div className={ classes.toolbar } />
			{
				loading
					? <CircularProgress />
					: <Suspense fallback={<CircularProgress />}>
						<Routes>
							{
								organization &&
									organization.id &&
									routes( organization.id ).filter( route => route.show || route.canView ).map( ( route, index ) => (
										<Route
											key={ index }
											exact
											path={ route.to }
											element={
												<route.component
													organization={organization || {}}
													loadOrganization={loadOrganization}
												/>
											}
										/>
									) )
							}

							<Route
								path="*"
								element={<NotFound />}
							/>
						</Routes>
					</Suspense>
			}
		</main>
	</div>;
}

Router.propTypes = {
	classes: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
	loadOrganization: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	logout: PropTypes.func.isRequired,
};

export default withStyles( styles )( Router );
