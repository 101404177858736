/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { debounce } from 'throttle-debounce';
import {
	Typography,

	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,

	TextField,
	Checkbox,

	Snackbar,
	Slide,

	Button,
} from '@material-ui/core';

import {
	Alert,
	Pagination,
} from '@material-ui/lab';

import {
} from '@material-ui/icons';

/**
 * Internal Dependencies
 */
import api from '../lib/api';
import { dollars } from '../lib/formatter';
import { calculateEmployeeSalary } from '../lib/dinamico';
import DollarInput from '../components/DollarInput';
import EditableTitle from '../components/EditableTitle';
import { withRouter } from '../lib/withRouter';

const styles = theme => ( {
	employee: {
		'& .MuiTextField-root': {
			margin: theme.spacing( 1 ),
		},
	},
	salaryBadge: {
		display: 'inline-block',
		padding: theme.spacing( 0.5, 1.5 ),
		margin: theme.spacing( 1, 1, 2 ),
		background: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
		borderRadius: '10px',
	},
	pagination: {
		margin: theme.spacing( 2, 1, 4 ),
	},
	reportMenu: {
		position: 'absolute',
		top: theme.spacing( 12 ),
		right: theme.spacing( 6 ),
	},
} );

class Employee extends Component {
	pdvPageSize = 100;

	state = {
		employee: {
			name: '',
			uuid: '',
			salary: 0,
			roleId: null,
		},
		role: {
			maxSalary: 0,
			baseSalary: 0,
			holdHarmless: 0,
			maxIncrease: 0,
		},

		employeeFeatures: [],
		allFeatures: [],
		totalFeatures: 0,
		featurePage: 1,

		error: '',
		showerror: false,
	};

	componentDidMount() {
		this.load();
	}

	componentDidUpdate( prevProps ) {
		if ( prevProps.organization.id !== this.props.organization.id ) {
			this.load();
		}
	}

	load() {
		api( `/organizations/${this.props.organization.id}/employees/${this.props.router.params.employeeId}` )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( employee => {
				if ( !employee ) {
					return;
				}

				this.setState( { employee }, this.loadPage );

				return employee.roleId;
			} )
			.then( role => {
				api( `/organizations/${this.props.organization.id}/roles/${role}` )
					.then( response => {
						if ( !response.ok ) {
							return;
						}

						return response.json();
					} )
					.then( response => {
						if ( !response ) {
							return;
						}

						this.setState( { role: response } );
					} );
			} );

		api( `/organizations/${this.props.organization.id}/employees/${this.props.router.params.employeeId}/features` )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( response => {
				if ( !response || !response.data ) {
					return;
				}

				this.setState( { employeeFeatures: response.data } );
			} );
	}

	loadPage( page = 1 ) {
		api( `/organizations/${this.props.organization.id}/roles/${this.state.employee.roleId}/features?page=${page}&number=${this.pdvPageSize}` )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( response => {
				if ( !response || !response.data ) {
					return;
				}

				this.setState( { allFeatures: response.data, totalFeatures: response.total, featurePage: page } );
			} );
	}

	handlePagination = ( event, page ) => {
		event.preventDefault();
		this.loadPage( page );
	};

	handleChange = event => {
		const { name, value } = event.target;

		this.setState( state => {
			const employee = state.employee;
			employee[name] = value;
			return { employee };
		}, this.debouncedSave );
	};

	debouncedSave = debounce( 1000, async () => {
		const url = `/organizations/${this.props.organization.id}/employees/${this.props.router.params.employeeId}`;
		api( url, 'PATCH', this.state.employee ).then( response => {
			if ( !response.ok ) {
				return response.json()
					.then( response => {
						this.showError( response.message || 'Error saving employee' );
					} );
			}

			return response.json();
		} );
	} );

	checked( feature ) {
		return !!this.state.employeeFeatures.find( f => f.id === feature.id );
	}

	handleAddRemoveFeature( feature ) {
		return event => {
			const { checked } = event.target;

			if ( checked ) {
				api( `/organizations/${this.props.organization.id}/employees/${this.props.router.params.employeeId}/features`, 'POST', feature )
					.then( response => {
						if ( !response.ok ) {
							return response.json()
								.then( response => {
									this.showError( response.message || 'Error adding PDV' );
								} );
						}

						const employeeFeatures = this.state.employeeFeatures;
						employeeFeatures.push( feature );

						this.setState( { employeeFeatures } );
					} );
			} else {
				api( `/organizations/${this.props.organization.id}/employees/${this.props.router.params.employeeId}/features/${feature.id}`, 'DELETE' )
					.then( response => {
						if ( !response.ok ) {
							return response.json()
								.then( response => {
									this.showError( response.message || 'Error removing PDV' );
								} );
						}

						const employeeFeatures = this.state.employeeFeatures.filter( f => {
							return f.id !== feature.id;
						} );

						this.setState( { employeeFeatures } );
					} );
			}
		};
	}

	handleNameChange( name ) {
		const { employee } = this.state;
		employee.name = name;

		this.setState( { employee }, this.debouncedSave );
	}

	showError( error ) {
		this.setState( { error, showerror: true } );
	}

	hideError() {
		this.setState( { showerror: false } );
	}

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<Snackbar
					open={this.state.showerror}
					onClose={this.hideError.bind( this )}
					TransitionComponent={Slide}
				>
					<Alert severity="error" variant="filled">
						{this.state.error}
					</Alert>
				</Snackbar>

				<EditableTitle
					value={this.state.employee.name}
					onChange={this.handleNameChange.bind( this )}
				/>

				<Typography>
					<span className={classes.salaryBadge}>
						Next Salary: <strong>{ dollars( calculateEmployeeSalary( this.state.employee.salary, this.state.role, this.state.employeeFeatures ) ) }</strong>
					</span>
				</Typography>

				<div className={classes.reportMenu}>
					<Button
						variant="outlined"
						to={`/roles/${this.state.employee.roleId}`}
						component={Link}
					>
						Edit Role
					</Button>
				</div>

				<div className={classes.employee}>
					<div>
						<TextField
							value={this.state.employee.uuid}
							onChange={this.handleChange}
							label="Employee ID"
							name="uuid"
							variant="outlined"
							size="small"
						/>
					</div>

					<div>
						<DollarInput
							value={this.state.employee.salary}
							onChange={this.handleChange}
							label="Salary"
							name="salary"
							variant="outlined"
							size="small"
						/>
					</div>
				</div>

				<Typography component="h2" variant="h2">
					PDVs
				</Typography>

				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox" />
							<TableCell>Name</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Weight</TableCell>
							<TableCell>Value</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ this.state.allFeatures.map( ( feature ) => {
							return <TableRow key={feature.id}>
								<TableCell>
									<Checkbox
										checked={this.checked( feature )}
										onChange={this.handleAddRemoveFeature( feature )}
									/>
								</TableCell>
								<TableCell>{feature.name}</TableCell>
								<TableCell>{feature.type}</TableCell>
								<TableCell>{feature.type === 'variable' && feature.weight}</TableCell>
								<TableCell>{ dollars( feature.value ) }</TableCell>
							</TableRow>;
						} ) }
					</TableBody>
				</Table>

				<Pagination
					className={classes.pagination}
					count={Math.ceil( this.state.totalFeatures / this.pdvPageSize )}
					page={this.state.featurePage}
					onChange={this.handlePagination}
				/>
			</React.Fragment>
		);
	}
}

Employee.propTypes = {
	classes: PropTypes.object.isRequired,
	router: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
};

export default withStyles( styles )( withRouter( Employee ) );
