/**
 * External Dependencies
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { debounce } from 'throttle-debounce';

import {
	Typography,

	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,

	Button,

	CircularProgress,

	Snackbar,
	Slide,
} from '@material-ui/core';

import {
	Alert,
	Pagination,
} from '@material-ui/lab';

import {
	AddCircle as AddCircleIcon,
	HighlightOff as DeleteIcon,
} from '@material-ui/icons';

/**
 * Internal Dependencies
 */
import api from '../lib/api';
import { dollars } from '../lib/formatter';
import ReportMenu from '../components/Reports/Menu';
import ConfirmationDialog from '../components/ConfirmationDialog';
import PercentInput from '../components/PercentInput';
import DollarInput from '../components/DollarInput';
import EditableTitle from '../components/EditableTitle';
import EmployeeCSVImportButton from '../components/EmployeeCSVImportButton';
import { withRouter } from '../lib/withRouter';

const styles = theme => ( {
	role: {
		'& .MuiTextField-root': {
			margin: theme.spacing( 1 ),
		},
	},
	salaryBadge: {
		display: 'inline-block',
		padding: theme.spacing( 0.5, 1.5 ),
		margin: theme.spacing( 1, 1, 2 ),
		background: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
		borderRadius: '10px',
	},
	addRowButton: {
		textAlign: 'center',
		padding: theme.spacing( 1 ),
		cursor: 'pointer',
		fontSize: '1.4rem',

		'& svg': {
			verticalAlign: 'middle',
		},
	},
	incrementDecrementButton: {
		margin: theme.spacing( 0, 1 ),
	},
	deleteColumn: {
		width: '40px',
	},
	deleteButton: {
		verticalAlign: 'middle',
		cursor: 'pointer',

		'&:hover': {
			color: 'red',
		},
	},
	budgetHint: {
		display: 'inline-flex',
		alignItems: 'center',
		minHeight: '55px',
	},
	realValueHint: {
		display: 'inline-flex',
		alignItems: 'center',
		minHeight: '40px',
	},
	pagination: {
		margin: theme.spacing( 2, 1, 4 ),
	},

	reportMenu: {
		position: 'absolute',
		top: theme.spacing( 12 ),
		right: theme.spacing( 6 ),
	},
	employeeButton: {
		marginRight: theme.spacing( 1 ),
	},
} );

class Role extends Component {
	pdvPageSize = 50;

	state = {
		role: {
			name: '',
			budget: 1.0,
			baseSalary: 0,
			maxSalary: 0,
			holdHarmless: 1.0,
			maxIncrease: 1.0,
			totalFixedValues: 0,
			totalRelativeWeights: 0,
			totalCurrentSalaries: 0,
			totalNextSalaries: 0,
			cost: null,
		},

		totalFixedFeatures: 0,
		fixedPDVPage: 1,
		fixedFeatures: [],

		totalVariableFeatures: 0,
		variablePDVPage: 1,
		variableFeatures: [],

		menuAnchor: null,

		error: '',
		showerror: false,
	};

	componentDidMount() {
		this.load();
	}

	componentDidUpdate( prevProps ) {
		if ( prevProps.organization.id !== this.props.organization.id ) {
			this.load();
		}
	}

	load() {
		this.loadRole();
		this.loadFeatures( 'fixed' );
		this.loadFeatures( 'variable' );
	}

	loadRole() {
		api( `/organizations/${this.props.organization.id}/roles/${this.props.router.params.roleId}` )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( role => {
				if ( !role ) {
					return;
				}

				this.setState( { role } );
			} );
	}

	loadFeatures( type, page = 1 ) {
		api(
			`/organizations/${this.props.organization.id}/roles/${this.props.router.params.roleId}/features?type=${type}&page=${page}&number=${this.pdvPageSize}`,
		)
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( features => {
				if ( !features ) {
					return;
				}

				if ( !features.data ) {
					return;
				}

				if ( type === 'fixed' ) {
					this.setState( { fixedFeatures: features.data, totalFixedFeatures: features.total, fixedPDVPage: page } );
				} else if ( type === 'variable' ) {
					this.setState( { variableFeatures: features.data, totalVariableFeatures: features.total, variablePDVPage: page } );
				}
			} );
	}

	handlePagination( type ) {
		return ( event, page ) => {
			event.preventDefault();
			this.loadFeatures( type, page );
		};
	}

	debouncedRoleUpdate = debounce( 2000, this.loadRole );

	debouncedRoleSave = debounce( 1000, async () => {
		const url = `/organizations/${this.props.organization.id}/roles/${this.props.router.params.roleId}`;
		api( url, 'PATCH', this.state.role ).then( response => {
			if ( !response.ok ) {
				return response.json()
					.then( response => {
						this.showError( response.message || 'Error updating the role' );
					} );
			}

			return response.json();
		} ).then( role => {
			if ( !role ) {
				return;
			}

			this.setState( { role } );
		} );
	} );

	debouncedSave = debounce( 1000, async () => {
		const { fixedFeatures, variableFeatures } = this.state;

		const updatedFeatures = []
			.concat( variableFeatures )
			.concat( fixedFeatures )
			.filter( feature => !!feature.changed )
			.filter( feature => {
				// Don't try to save incomplete/invalid features
				if ( !feature.name ) {
					return false;
				}

				if ( !feature.type ) {
					return false;
				}

				if ( feature.value === null && feature.weight === null ) {
					return false;
				}

				return true;
			} );

		for ( const updatedFeature of updatedFeatures ) {
			let method = 'POST';
			let url = `/organizations/${this.props.organization.id}/roles/${this.props.router.params.roleId}/features`;

			if ( updatedFeature.id ) {
				method = 'PATCH';
				url = url + `/${updatedFeature.id}`;
			}

			if ( updatedFeature.type === 'variable' && !updatedFeature.weight ) {
				updatedFeature.weight = 0;
			}

			api( url, method, updatedFeature )
				.then( response => {
					if ( !response.ok ) {
						return response.json()
							.then( response => {
								this.showError( response.message || 'Error updating the PDV' );
							} );
					}

					return response.json();
				} )
				.then( feature => {
					if ( !feature ) {
						return;
					}

					// Remove changed flag
					if ( feature.type === 'fixed' ) {
						fixedFeatures[updatedFeature.index] = feature;
						this.setState( { fixedFeatures } );
					} else {
						variableFeatures[updatedFeature.index] = feature;
						this.setState( { variableFeatures } );
					}
				} );
		}
	} );

	addRow( type ) {
		const array = type === 'fixed' ? 'fixedFeatures' : 'variableFeatures';

		return () => {
			this.setState( state => {
				const features = state[array].concat( {
					name: '',
					type,
					weight: 0,
					value: 0,
				} );

				return { [array]: features };
			} );
		};
	}

	removeRow( type, index ) {
		const array = type === 'fixed' ? 'fixedFeatures' : 'variableFeatures';

		return () => {
			this.setState( state => {
				const features = state[array];
				const role = state.role;

				const removed = features[parseInt( index, 10 )];
				api( `/organizations/${this.props.organization.id}/roles/${this.props.router.params.roleId}/features/${removed.id}`, 'DELETE' );

				if ( removed.type === 'variable' ) {
					role.totalRelativeWeights -= removed.weight;
				} else if ( removed.type === 'fixed' ) {
					role.totalFixedValues -= removed.value;
				}

				features.splice( index, 1 );
				return { [array]: features, role };
			} );
		};
	}

	handleChangeForRow( type, row, field ) {
		return event => {
			const { value } = event.target;
			this.handlePDVChange( type, row, field, value );
		};
	}

	handleIncrementPDV( type, row, field ) {
		return event => {
			event.preventDefault();
			this.incrementDecrementPDV( type, row, field, 'increment' );
		};
	}

	handleDecrementPDV( type, row, field ) {
		return event => {
			event.preventDefault();
			this.incrementDecrementPDV( type, row, field, 'decrement' );
		};
	}

	incrementDecrementPDV( type, row, field, action ) {
		const array = type === 'fixed' ? 'fixedFeatures' : 'variableFeatures';
		const features = this.state[array];
		const feature = features[parseInt( row, 10 )];

		if ( type === 'variable' && field === 'weight' ) {
			if ( action === 'increment' ) {
				this.handlePDVChange( type, row, field, feature.weight + 1 );
			} else if ( action === 'decrement' ) {
				this.handlePDVChange( type, row, field, feature.weight - 1 );
			}
		} else if ( type === 'fixed' && field === 'value' ) {
			if ( action === 'increment' ) {
				this.handlePDVChange( type, row, field, feature.value + 1 );
			} else if ( action === 'decrement' ) {
				this.handlePDVChange( type, row, field, feature.value - 1 );
			}
		}
	}

	handlePDVChange( type, row, field, value ) {
		const array = type === 'fixed' ? 'fixedFeatures' : 'variableFeatures';
		this.setState( state => {
			const features = state[array];
			const feature = features[parseInt( row, 10 )];
			const role = state.role;

			if ( type === 'variable' && field === 'weight' ) {
				const diff = feature.weight - value;
				role.totalRelativeWeights -= diff;
			} else if ( type === 'fixed' && field === 'value' ) {
				const diff = feature.value - value;
				role.totalFixedValues -= diff;
			}

			role.cost = null;

			features[parseInt( row, 10 )][field] = value;
			features[parseInt( row, 10 )].changed = true;
			features[parseInt( row, 10 )].index = row;

			return { [array]: features, role };
		}, () => {
			this.debouncedSave();
			this.debouncedRoleUpdate();
		} );
	}

	handleChangeForRole( field ) {
		return event => {
			let { value } = event.target;
			value = Number.parseFloat( value );

			this.setState( state => {
				const role = state.role;
				role[field] = value;

				// Reset the cost for async update
				switch ( field ) {
				case 'baseSalary':
				case 'maxSalary':
				case 'holdHarmless':
				case 'maxIncrease':
					role.cost = null;
					break;
				}

				return { role };
			}, () => {
				this.debouncedRoleSave();
			} );
		};
	}

	handleNameChange( name ) {
		const { role } = this.state;
		role.name = name;

		this.setState( { role }, this.debouncedRoleSave );
	}

	budget() {
		const budget = ( this.state.role.budget || 0 ) / 100;
		const totalSalaries = this.state.role.totalCurrentSalaries;
		return budget * totalSalaries;
	}

	relativePDVValue( weight ) {
		const {
			maxSalary,
			baseSalary,
			totalFixedValues,
			totalRelativeWeights,
		} = this.state.role;

		if ( maxSalary < baseSalary ) {
			return 0;
		}

		const variableSalary = maxSalary - baseSalary - totalFixedValues;
		if ( variableSalary < 0 ) {
			return 0;
		}

		return variableSalary * ( weight / totalRelativeWeights );
	}

	showError( error ) {
		this.setState( { error, showerror: true } );
	}

	hideError() {
		this.setState( { showerror: false } );
	}

	render() {
		const { classes, router: { params: { roleId } } } = this.props;
		return (
			<React.Fragment>
				<Snackbar
					open={this.state.showerror}
					onClose={this.hideError.bind( this )}
					TransitionComponent={Slide}
				>
					<Alert severity="error" variant="filled">
						{this.state.error}
					</Alert>
				</Snackbar>

				<EditableTitle
					value={this.state.role.name}
					onChange={this.handleNameChange.bind( this )}
				/>

				<span className={classes.salaryBadge}>
					Total Cost {
						this.state.role.cost !== null
							? <strong>{ dollars( this.state.role.cost ) }</strong>
							: <CircularProgress size="1em" />
					}
				</span>

				<div className={classes.reportMenu}>
					<EmployeeCSVImportButton organization={this.props.organization} roleId={roleId} />
					<Button
						to={`/employees?roleId=${roleId}` }
						component={Link}
						variant="outlined"
						className={ classes.employeeButton }
					>
						Employees
					</Button>
					<ReportMenu roleId={roleId} />
				</div>

				<div className={classes.role}>
					<div>
						<PercentInput
							value={this.state.role.budget || 0}
							onChange={this.handleChangeForRole( 'budget' )}
							label="Budget"
							name="budget"
							variant="outlined"
							size="small"
							decimalInput
						/>
						<span className={ classes.budgetHint }>{ dollars( this.budget() ) }</span>
					</div>
					<div>
						<DollarInput
							value={this.state.role.baseSalary || 0}
							onChange={this.handleChangeForRole( 'baseSalary' )}
							label="Base Salary"
							name="base-salary"
							variant="outlined"
							size="small"
						/>
					</div>
					<div>
						<DollarInput
							value={this.state.role.maxSalary || 0}
							onChange={this.handleChangeForRole( 'maxSalary' )}
							label="Max Salary"
							name="max-salary"
							variant="outlined"
							size="small"
						/>
					</div>
					<div>
						<PercentInput
							value={this.state.role.holdHarmless || 0}
							onChange={this.handleChangeForRole( 'holdHarmless' )}
							label="Hold Harmless"
							name="hold-harmless"
							variant="outlined"
							size="small"
							decimalInput
						/>
					</div>
					<div>
						<PercentInput
							value={this.state.role.maxIncrease || 0}
							onChange={this.handleChangeForRole( 'maxIncrease' )}
							label="Max Increase"
							name="max-increase"
							variant="outlined"
							size="small"
							decimalInput
						/>
					</div>
				</div>

				<Typography component="h2" variant="h2">
					Variable PDVs
				</Typography>

				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Weight</TableCell>
							<TableCell>Value</TableCell>
							<TableCell className={classes.deleteColumn} />
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.variableFeatures.map( ( feature, index ) => {
							return (
								<TableRow key={index}>
									<TableCell>
										<TextField
											label="Name"
											value={feature.name}
											error={!feature.name && feature.weight}
											helperText={!feature.name && feature.weight ? 'Set the PDV name' : ''}
											onChange={this.handleChangeForRow(
												'variable',
												index,
												'name',
											)}
											variant="outlined"
											size="small"
											fullWidth
										/>
									</TableCell>
									<TableCell>
										<Button
											onClick={this.handleDecrementPDV( 'variable', index, 'weight' )}
											variant="contained"
											color='primary'
											className={ classes.incrementDecrementButton }
										>-</Button>
										<TextField
											label={'Weight'}
											value={feature.weight}
											onChange={this.handleChangeForRow(
												'variable',
												index,
												'weight',
											)}
											variant="outlined"
											size="small"
										/>
										<Button
											onClick={this.handleIncrementPDV( 'variable', index, 'weight' )}
											variant="contained"
											color='primary'
											className={ classes.incrementDecrementButton }
										>+</Button>
									</TableCell>
									<TableCell>
										<span className={ classes.realValueHint }>{ dollars( this.relativePDVValue( feature.weight ) ) }</span>
									</TableCell>
									<TableCell>
										<ConfirmationDialog
											action={this.removeRow(
												'variable',
												index,
											)}
											component={ DeleteIcon }
											title="Delete PDV"
											description="Are you sure you want to delete this PDV? It can not be recovered later."
										/>
									</TableCell>
								</TableRow>
							);
						} )}
						<TableRow>
							<TableCell
								colSpan={3}
								className={classes.addRowButton}
								onClick={this.addRow( 'variable' )}
							>
								<AddCircleIcon /> Add PDV
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				<Pagination
					className={classes.pagination}
					count={Math.ceil( this.state.totalVariableFeatures / this.pdvPageSize )}
					page={this.state.variablePDVPage}
					onChange={this.handlePagination( 'variable' )}
				/>

				<Typography component="h2" variant="h2">
					Fixed PDVs
				</Typography>

				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Value</TableCell>
							<TableCell></TableCell>
							<TableCell className={classes.deleteColumn} />
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.fixedFeatures.map( ( feature, index ) => {
							return (
								<TableRow key={index}>
									<TableCell>
										<TextField
											label='Name'
											name='name'
											value={feature.name}
											error={!feature.name && feature.value}
											helperText={!feature.name && feature.value ? 'Set the PDV name' : ''}
											onChange={this.handleChangeForRow( 'fixed', index, 'name' )}
											variant="outlined"
											size="small"
											fullWidth
										/>
									</TableCell>
									<TableCell>
										<Button
											onClick={this.handleDecrementPDV( 'fixed', index, 'value' )}
											variant="contained"
											color='primary'
											className={ classes.incrementDecrementButton }
										>-</Button>
										<DollarInput
											label='Value'
											name='value'
											value={feature.value}
											onChange={this.handleChangeForRow(
												'fixed',
												index,
												'value',
											)}
											variant="outlined"
											size="small"
										/>
										<Button
											onClick={this.handleIncrementPDV( 'fixed', index, 'value' )}
											variant="contained"
											color='primary'
											className={ classes.incrementDecrementButton }
										>+</Button>
									</TableCell>
									<TableCell></TableCell>
									<TableCell>
										<ConfirmationDialog
											action={this.removeRow(
												'fixed',
												index,
											)}
											component={ DeleteIcon }
											title="Delete PDV"
											description="Are you sure you want to delete this PDV? It can not be recovered later."
										/>
									</TableCell>
								</TableRow>
							);
						} )}
						<TableRow>
							<TableCell
								colSpan={3}
								className={classes.addRowButton}
								onClick={this.addRow( 'fixed' )}
							>
								<AddCircleIcon /> Add PDV
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				<Pagination
					className={classes.pagination}
					count={Math.ceil( this.state.totalFixedFeatures / this.pdvPageSize )}
					page={this.state.fixedPDVPage}
					onChange={this.handlePagination( 'fixed' )}
				/>
			</React.Fragment>
		);
	}
}

Role.propTypes = {
	classes: PropTypes.object.isRequired,
	router: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
};

export default withStyles( styles )( withRouter( Role ) );
