/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
	Typography,

	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,

	Modal,
	Fab,
	Link,
	Button,

	TextField,

	Snackbar,
	Slide,
} from '@material-ui/core';

import {
	Alert,
	Pagination,
} from '@material-ui/lab';

import {
	Add as AddIcon,
	HighlightOff as DeleteIcon,
} from '@material-ui/icons';

/**
 * Internal Dependencies
 */
import api from '../lib/api';
import { dollars, percent } from '../lib/formatter';
import ConfirmationDialog from '../components/ConfirmationDialog';
import DollarInput from '../components/DollarInput';
import PercentInput from '../components/PercentInput';

const styles = theme => ( {
	fab: {
		position: 'absolute',
		top: theme.spacing( 9 ),
		right: theme.spacing( 2 ),

		'& > *': {
			margin: theme.spacing( 1 ),
		},
	},
	modal: {
		display: 'flex',
		padding: theme.spacing( 1 ),
		alignItems: 'center',
		justifyContent: 'center',

		'& .MuiTextField-root': {
			margin: theme.spacing( 1 ),
		},
	},
	paper: {
		width: '75%',
		maxWidth: '800px',
		backgroundColor: theme.palette.background.paper,
		border: '3px solid #000',
		borderColor: theme.palette.primary.main,
		boxShadow: theme.shadows[5],
		padding: theme.spacing( 2, 4, 3 ),
	},
	saveButton: {
		margin: theme.spacing( 2, 1 ),
	},
	deleteColumn: {
		width: '40px',
	},
	deleteButton: {
		verticalAlign: 'middle',
		cursor: 'pointer',

		'&:hover': {
			color: 'red',
		},
	},
	pagination: {
		margin: theme.spacing( 2, 1, 4 ),
	},
} );

class Roles extends Component {
	state = {
		error: '',
		showerror: false,

		addRoleModalIsOpen: false,
		newRole: {},

		totalRoles: 0,
		rolePage: 1,
		roles: [],
	};

	componentDidMount() {
		this.loadRoles();
	}

	componentDidUpdate( prevProps ) {
		if ( prevProps.organization.id !== this.props.organization.id ) {
			this.loadRoles();
		}
	}

	loadRoles( page = 1 ) {
		api( `/organizations/${this.props.organization.id}/roles?number=20&page=${page}` )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( roles => {
				if ( !roles ) {
					return;
				}

				this.setState( { rolePage: page, roles: roles.data, totalRoles: roles.total } );
			} );
	}

	handlePagination = ( event, page ) => {
		event.preventDefault();
		this.loadRoles( page );
	};

	handleModalOpen( event ) {
		event.preventDefault();
		this.setState( { addRoleModalIsOpen: true } );
	}

	handleModalClose() {
		this.setState( { newRole: {}, addRoleModalIsOpen: false } );
	}

	handleNewRoleChanges( field ) {
		return event => {
			const value = event.target.value;

			this.setState( state => {
				const newRole = {
					...state.newRole,
					[field]: value,
				};

				return { newRole };
			} );
		};
	}

	handleSaveNewRole() {
		const newRole = {
			organizationId: this.props.organization.id,
			...this.state.newRole,
		};

		api( `/organizations/${this.props.organization.id}/roles`, 'POST', newRole )
			.then( async response => {
				if ( !response.ok ) {
					const error = await response.json();
					this.showError( error.message || 'Error saving new role' );

					return;
				}

				return response.json();
			} )
			.then( role => {
				if ( !role ) {
					return;
				}

				this.loadRoles( this.state.rolePage );
				this.handleModalClose();
			} );
	}

	showError( error ) {
		this.setState( { error, showerror: true } );
	}

	hideError() {
		this.setState( { showerror: false } );
	}

	removeRow( index ) {
		const role = this.state.roles[parseInt( index, 10 )];
		return () => {
			api( `/organizations/${this.props.organization.id}/roles/${role.id}`, 'DELETE' )
				.then( async response => {
					if ( !response.ok ) {
						const error = await response.json();
						this.showError( error.message || 'Could not delete role' );

						return;
					}

					const roles = this.state.roles;
					roles.splice( index, 1 );
					this.setState( { roles } );
				} );
		};
	}

	render() {
		const { classes } = this.props;

		return <React.Fragment>
			<Snackbar
				open={this.state.showerror}
				onClose={this.hideError.bind( this )}
				TransitionComponent={Slide}
			>
				<Alert severity="error" variant="filled">
					{this.state.error}
				</Alert>
			</Snackbar>

			<Typography component="h1" variant="h1">
				Roles
			</Typography>

			<div className={ classes.fab }>
				<Fab color="primary" aria-label="add" onClick={this.handleModalOpen.bind( this )}>
					<AddIcon />
				</Fab>
				<Modal
					open={this.state.addRoleModalIsOpen}
					onClose={this.handleModalClose.bind( this )}
					className={classes.modal}
				>
					<div className={classes.paper}>
						<Typography component="h2" variant="h2">Add Role</Typography>

						<div>
							<TextField
								variant="outlined"
								size="small"
								label="Name"
								name="name"
								onChange={this.handleNewRoleChanges( 'name' )}
							/>
						</div>

						<div>
							<PercentInput
								variant="outlined"
								size="small"
								label="Budget"
								name="budget"
								onChange={this.handleNewRoleChanges( 'budget' )}
								decimalInput
							/>
						</div>

						<div>
							<DollarInput
								variant="outlined"
								size="small"
								label="Base Salary"
								name="base-salary"
								onChange={this.handleNewRoleChanges( 'baseSalary' )}
							/>
						</div>

						<div>
							<DollarInput
								variant="outlined"
								size="small"
								label="Max Salary"
								name="max-salary"
								onChange={this.handleNewRoleChanges( 'maxSalary' )}
							/>
						</div>

						<div>
							<PercentInput
								variant="outlined"
								size="small"
								label="Hold Harmless"
								name="hold-harmless"
								onChange={this.handleNewRoleChanges( 'holdHarmless' )}
							/>
						</div>

						<div>
							<PercentInput
								variant="outlined"
								size="small"
								label="Max Increase"
								name="Max Increase"
								onChange={this.handleNewRoleChanges( 'maxIncrease' )}
							/>
						</div>

						<Button
							onClick={this.handleSaveNewRole.bind( this )}
							className={ classes.saveButton }
							variant="contained"
							color="primary"
						>
							Save
						</Button>
					</div>
				</Modal>
			</div>

			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Budget</TableCell>
						<TableCell>Hold Harmless</TableCell>
						<TableCell>Max Increase</TableCell>
						<TableCell>Base Salary</TableCell>
						<TableCell>Max Salary</TableCell>
						<TableCell className={classes.deleteColumn} />
					</TableRow>
				</TableHead>
				<TableBody>
					{ this.state.roles.map( ( role, index ) => {
						return <TableRow key={ index }>
							<TableCell><Link to={ `/roles/${role.id}` } component={ RouterLink }>{ role.name }</Link></TableCell>
							<TableCell>{ percent( role.budget / 100 ) }</TableCell>
							<TableCell>{ percent( role.holdHarmless / 100 ) }</TableCell>
							<TableCell>{ percent( role.maxIncrease / 100 ) }</TableCell>
							<TableCell>{ dollars( role.baseSalary ) }</TableCell>
							<TableCell>{ dollars( role.maxSalary ) }</TableCell>
							<TableCell>
								<ConfirmationDialog
									action={ this.removeRow( index ) }
									component={ DeleteIcon }
									title="Delete Role"
									description="Are you sure you want to delete this role? It can not be recovered later."
								/>
							</TableCell>
						</TableRow>;
					} ) }
				</TableBody>
			</Table>

			<Pagination
				className={classes.pagination}
				count={Math.ceil( this.state.totalRoles / 20 )}
				page={this.state.rolePage}
				onChange={this.handlePagination}
			/>
		</React.Fragment>;
	}
}

Roles.propTypes = {
	classes: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
};

export default withStyles( styles )( Roles );
