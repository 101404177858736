/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { debounce } from 'throttle-debounce';
import { Link as RouterLink } from 'react-router-dom';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,

	Modal,
	Fab,
	Link,
	Button,

	Typography,
	TextField,

	Snackbar,
	Slide,
} from '@material-ui/core';

import {
	Alert,
	Pagination,
} from '@material-ui/lab';

import {
	Add as AddIcon,
	HighlightOff as DeleteIcon,
} from '@material-ui/icons';

/**
 * Internal Dependencies
 */
import api from '../lib/api';
import ConfirmationDialog from '../components/ConfirmationDialog';
import RoleDropdown from '../components/RoleDropdown';
import DollarInput from '../components/DollarInput';
import { dollars } from '../lib/formatter';
import { withRouter } from '../lib/withRouter';

const styles = theme => ( {
	filters: {
		display: 'inline-block',
		padding: theme.spacing( 0, 2 ),
	},
	fab: {
		position: 'absolute',
		top: theme.spacing( 9 ),
		right: theme.spacing( 2 ),

		'& > *': {
			margin: theme.spacing( 1 ),
		},
	},
	extendedIcon: {
		marginRight: theme.spacing( 1 ),
	},
	modal: {
		display: 'flex',
		padding: theme.spacing( 1 ),
		alignItems: 'center',
		justifyContent: 'center',

		'& .MuiTextField-root': {
			margin: theme.spacing( 1 ),
		},
	},
	paper: {
		width: '75%',
		maxWidth: '800px',
		backgroundColor: theme.palette.background.paper,
		border: '3px solid #000',
		borderColor: theme.palette.primary.main,
		boxShadow: theme.shadows[5],
		padding: theme.spacing( 2, 4, 3 ),
	},
	saveButton: {
		margin: theme.spacing( 2, 1 ),
	},
	deleteColumn: {
		width: '40px',
	},
	deleteButton: {
		verticalAlign: 'middle',
		cursor: 'pointer',

		'&:hover': {
			color: 'red',
		},
	},
	pagination: {
		margin: theme.spacing( 2, 1, 4 ),
	},

	roleDropdown: {
		margin: theme.spacing( 0, 1 ),
	},
	roleButton: {
		marginRight: theme.spacing( 1 ),
	},

	search: {
		margin: theme.spacing( 0, 1 ),
	},
} );

class Employee extends Component {
	state = {
		error: '',
		showerror: false,

		search: null,

		addEmployeeModalIsOpen: false,
		newEmployee: {},

		importEmployeesModalIsOpen: false,
		importErrors: [],

		totalEmployees: 0,
		employeePage: 1,
		employees: [],

		roleId: null,
	};

	componentDidMount() {
		this.loadEmployees();
	}

	componentDidUpdate( prevProps ) {
		if ( prevProps.organization.id !== this.props.organization.id ) {
			this.loadEmployees();
		}
	}

	loadEmployees( page = 1, search ) {
		let url = `/organizations/${this.props.organization.id}/employees?number=20&page=${page}`;
		if ( search && search.length ) {
			url += `&name=${search}`;
		}

		const params = new URLSearchParams( window.location.search );
		this.setState( { roleId: params.get( 'roleId' ) } );

		if ( this.state.roleFilter ) {
			url += `&roleId=${this.state.roleFilter}`;
		} else if ( params.get( 'roleId' ) ) {
			url += `&roleId=${params.get( 'roleId' )}`;
		}

		api( url )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( employees => {
				if ( !employees ) {
					return;
				}

				if ( !employees.data ) {
					return;
				}

				this.setState( {
					employeePage: employees.page,
					totalEmployees: employees.total,
					employees: employees.data,
				} );
			} );
	}

	debouncedQuery = debounce( 400, ( page, search ) => this.loadEmployees( 1, search ) );

	search = ( event ) => {
		const search = event.target.value;
		const filteredEmployees = this.state.employees.filter( employee => {
			return employee.name.toLowerCase().includes( search.toLowerCase() );
		} );

		this.setState( { employees: filteredEmployees, search } );
		this.debouncedQuery( 1, search );
	};

	handlePagination = ( event, page ) => {
		event.preventDefault();
		this.loadEmployees( page, this.state.search );
	};

	handleUploadOpen( event ) {
		event.preventDefault();
		this.setState( { importEmployeesModalIsOpen: true, importErrors: [] } );
	}

	handleUploadClose() {
		this.setState( { importEmployeesModalIsOpen: false, importErrors: [] } );
	}

	handleCSVUpload = async ( data, fileInfo ) => {
		const employees = this.state.employees;
		const errors = [];

		for ( const employee of data ) {
			if ( this.state.roleFilter && !employee.roleId ) {
				// Set the role to the current filtered role
				// if no role is set in the CSV
				employee.roleId = this.state.roleFilter;
			}

			const response = await api( `/organizations/${this.props.organization.id}/employees`, 'POST', employee );
			if ( response.ok ) {
				const body = await response.json();
				employee.uuid = body.uuid;
				employees.push( employee );
			} else {
				const body = await response.json();
				employee.error = body;
				errors.push( employee );
			}
		}

		this.setState( { importErrors: errors } );

		if ( errors.length === 0 ) {
			this.handleUploadClose();
			this.loadEmployees( this.state.employeePage );
		}
	};

	handleCSVUploadError() {
		// TODO
	}

	handleModalOpen( event ) {
		event.preventDefault();
		this.setState( { addEmployeeModalIsOpen: true } );
	}

	handleModalClose() {
		this.setState( { newEmployee: {}, addEmployeeModalIsOpen: false } );
	}

	handleNewEmployeeChanges( field ) {
		if ( field === 'roleId' ) {
			return role => {
				role = parseInt( role, 10 );

				this.setState( state => {
					const newEmployee = {
						...state.newEmployee,
						[field]: role,
					};

					return { newEmployee };
				} );
			};
		}

		return event => {
			const { value } = event.target;
			this.setState( state => {
				const newEmployee = {
					...state.newEmployee,
					[field]: value,
				};

				return { newEmployee };
			} );
		};
	}

	handleSaveNewEmployee() {
		const newEmployee = {
			organizationId: this.props.organization.id,
			...this.state.newEmployee,
		};

		api( `/organizations/${this.props.organization.id}/employees`, 'POST', newEmployee )
			.then( response => {
				if ( !response.ok ) {
					response.json().then( error => {
						this.showError( error.message || 'Error saving new employee' );
					} );

					return;
				}

				return response.json();
			} )
			.then( employee => {
				if ( !employee ) {
					return;
				}

				this.loadEmployees( this.state.employeePage );
				this.handleModalClose();
			} );
	}

	showError( error ) {
		this.setState( { error, showerror: true } );
	}

	hideError() {
		this.setState( { showerror: false } );
	}

	removeRow( index ) {
		const employee = this.state.employees[parseInt( index, 10 )];
		return () => {
			api( `/organizations/${this.props.organization.id}/employees/${employee.id}`, 'DELETE' )
				.then( response => {
					if ( !response.ok ) {
						return;
					}

					this.loadEmployees( this.state.employeePage );
				} );
		};
	}

	handleRoleFilter = role => {
		if ( role === 'all' ) {
			role = null;
		}

		this.setState( {
			roleFilter: role,
		}, this.loadEmployees );
	};

	render() {
		const { classes, organization } = this.props;

		return <React.Fragment>
			<Snackbar
				open={this.state.showerror}
				onClose={this.hideError.bind( this )}
				TransitionComponent={Slide}
			>
				<Alert severity="error" variant="filled">
					{this.state.error}
				</Alert>
			</Snackbar>

			<Typography component="h1" variant="h1">
				Employees
			</Typography>

			<div className={ classes.fab }>
				<div className={classes.filters}>
					{ this.state.roleId &&
						<Button
							to={`/roles/${this.state.roleId}` }
							component={RouterLink}
							variant="outlined"
							className={ classes.roleButton }
						>
							Edit Role
						</Button>
					}

					<TextField placeholder="Search&hellip;" variant="outlined" size="small" className={ classes.search } onChange={ this.search.bind( this ) } />

					<RoleDropdown
						organization={organization}
						onChange={this.handleRoleFilter}
					/>
				</div>

				<Fab
					onClick={this.handleModalOpen.bind( this )}
					color="primary"
					aria-label="add"
				>
					<AddIcon />
				</Fab>
				<Modal
					open={this.state.addEmployeeModalIsOpen}
					onClose={this.handleModalClose.bind( this )}
					className={classes.modal}
				>
					<div className={classes.paper}>
						<Typography component="h2" variant="h2">Add Employee</Typography>

						<div>
							<TextField
								variant="outlined"
								size="small"
								label="Name"
								name="name"
								onChange={this.handleNewEmployeeChanges( 'name' )}
							/>
						</div>

						<div>
							<DollarInput
								variant="outlined"
								size="small"
								label="Salary"
								name="salary"
								onChange={this.handleNewEmployeeChanges( 'salary' )}
							/>
						</div>

						<div className={classes.roleDropdown}>
							<RoleDropdown
								organization={organization}
								onChange={this.handleNewEmployeeChanges( 'roleId' )}
								defaultValue="Role"
							/>
						</div>

						<Button
							onClick={this.handleSaveNewEmployee.bind( this )}
							className={ classes.saveButton }
							variant="contained"
							color="primary"
						>
							Save
						</Button>
					</div>
				</Modal>
			</div>

			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Employee ID</TableCell>
						<TableCell>Role</TableCell>
						<TableCell>Salary</TableCell>
						<TableCell className={classes.deleteColumn} />
					</TableRow>
				</TableHead>
				<TableBody>
					{ this.state.employees.map( ( employee, index ) => {
						return <TableRow key={ index }>
							<TableCell><Link to={ `/employees/${employee.id}` } component={ RouterLink }>{ employee.name }</Link></TableCell>
							<TableCell>{ employee.uuid }</TableCell>
							<TableCell>{ employee.roleName }</TableCell>
							<TableCell>{ dollars( employee.salary ) }</TableCell>
							<TableCell>
								<ConfirmationDialog
									action={ this.removeRow( index ) }
									component={ DeleteIcon }
									title="Delete Employee"
									description="Are you sure you want to delete this employee? It can not be recovered later."
								/>
							</TableCell>
						</TableRow>;
					} ) }
				</TableBody>
			</Table>

			<Pagination
				className={classes.pagination}
				count={Math.ceil( this.state.totalEmployees / 20 )}
				page={this.state.employeePage}
				onChange={this.handlePagination}
			/>
		</React.Fragment>;
	}
}

Employee.propTypes = {
	classes: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
};

export default withStyles( styles )( withRouter( Employee ) );
