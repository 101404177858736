/**
 * External Dependencies
 */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { debounce } from 'throttle-debounce';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
	Typography,
	TextField,

	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,

	Modal,
	Fab,
	Button,

	Snackbar,
	Slide,
} from '@material-ui/core';

import {
	Alert,
	Pagination,
} from '@material-ui/lab';

import {
	Add as AddIcon,
} from '@material-ui/icons';

/**
 * Internal Dependencies
 */
import api, { currentUserCan } from '../lib/api';

dayjs.extend( utc );
dayjs.extend( timezone );
dayjs.tz.setDefault( 'UTC' );

const styles = theme => ( {
	filters: {
		display: 'inline-block',
		padding: theme.spacing( 0, 2 ),
	},
	fab: {
		position: 'absolute',
		top: theme.spacing( 9 ),
		right: theme.spacing( 2 ),

		'& > *': {
			margin: theme.spacing( 1 ),
		},
	},
	modal: {
		display: 'flex',
		padding: theme.spacing( 1 ),
		alignItems: 'center',
		justifyContent: 'center',

		'& .MuiTextField-root': {
			margin: theme.spacing( 1 ),
		},
	},
	paper: {
		width: '75%',
		maxWidth: '800px',
		backgroundColor: theme.palette.background.paper,
		border: '3px solid #000',
		borderColor: theme.palette.primary.main,
		boxShadow: theme.shadows[5],
		padding: theme.spacing( 2, 4, 3 ),
	},
	saveButton: {
		margin: theme.spacing( 2, 1 ),
	},

	organizationRow: {
		cursor: 'pointer',
		'&:hover': {
			'background-color': theme.palette.background.highlight,
		},
	},
	pagination: {
		margin: theme.spacing( 2, 1, 4 ),
	},
} );

function Organizations( props ) {
	const { classes } = props;
	const navigate = useNavigate();

	const [ organizations, updateOrganizations ] = useState( [] );
	const [ name, updateName ] = useState( '' );
	const [ total, updateTotal ] = useState( 0 );
	const [ pages, updatePages ] = useState( 1 );

	const [ newOrganization, setNewOrganization ] = useState( {} );
	const [ modalOpen, setModalOpen ] = useState( false );
	const openModal = () => setModalOpen( true );
	const closeModal = () => setModalOpen( false );

	const [ error, setError ] = useState( '' );
	const [ shouldShowError, setShowError ] = useState( false );
	const hideError = () => setShowError( false );
	const showError = ( message ) => {
		setError( message );
		setShowError( true );
	};

	useEffect( () => {
		query( pages, name );
	}, [ pages ] );

	const query = ( page = 1, search ) => {
		let url = `/organizations?page=${page}&number=20`;
		if ( search && search.length ) {
			url += `&name=${search}`;
		}

		api( url )
			.then( res => {
				if ( !res.ok ) {
					return;
				}

				return res.json();
			} )
			.then( res => {
				if ( !res ) {
					return;
				}

				updateTotal( res.total );
				updatePages( res.page );
				updateOrganizations( res.data );
			} );
	};

	const debouncedQuery = debounce( 400, ( page, search ) => query( 1, search ) );

	const search = e => {
		const search = e.target.value;
		const filteredOrganizations = organizations.filter( organization => {
			return organization.name.toLowerCase().includes( search.toLowerCase() );
		} );

		updateOrganizations( filteredOrganizations );
		updateName( search );
		debouncedQuery( 1, search );
	};

	const selectOrganization = e => {
		const id = parseInt( e.target.parentElement.getAttribute( 'data-organization' ), 10 );
		const org = organizations.find( org => org.id === id );
		if ( org ) {
			props.loadOrganization( id );
			navigate( '/' );
		}
	};

	const handlePagination = ( event, page ) => {
		event.preventDefault();
		updatePages( page );
	};

	const handleNewOrganizationChanges = field => {
		return event => {
			event.preventDefault();

			const { value } = event.target;
			setNewOrganization( { ...newOrganization, [field]: value } );
		};
	};

	const saveNewOrganization = () => {
		api( '/auth/signup', 'POST', newOrganization )
			.then( async response => {
				if ( !response.ok ) {
					const body = await response.json();

					switch ( body.code ) {
					case 'ER_DUP_ENTRY':
						showError( 'Email address already exists' );
						return;

					default:
						showError( body.message );
						return;
					}
				}

				closeModal();
				query( pages, name );
				setNewOrganization( {} );
			} )
			.catch( _ => {
				showError( 'Unknown error, please try again' );
			} );
	};

	return <>
		<Typography component="h1" variant="h1">
			Organizations
		</Typography>

		{ currentUserCan( 'organization', 'write' ) &&
			<>
				<Snackbar
					open={shouldShowError}
					onClose={hideError}
					TransitionComponent={Slide}
				>
					<Alert severity="error" variant="filled">
						{error}
					</Alert>
				</Snackbar>

				<div className={ classes.fab }>
					<div className={classes.filters}>
						<TextField placeholder="Search&hellip;" variant="outlined" size="small" onChange={ search }/>
					</div>

					<Fab color="primary" aria-label="add" onClick={openModal}>
						<AddIcon />
					</Fab>
					<Modal
						open={modalOpen}
						onClose={closeModal}
						className={classes.modal}
					>
						<div className={classes.paper}>
							<Typography component="h2" variant="h2">Add Organization</Typography>

							<div>
								<TextField
									variant="outlined"
									size="small"
									label="Organization Name"
									name="name"
									onChange={handleNewOrganizationChanges( 'organizationName' )}
								/>
							</div>

							<div>
								<TextField
									variant="outlined"
									size="small"
									label="Admin Name"
									name="email"
									onChange={handleNewOrganizationChanges( 'userName' )}
								/>
							</div>

							<div>
								<TextField
									variant="outlined"
									size="small"
									label="Admin Email"
									name="email"
									onChange={handleNewOrganizationChanges( 'email' )}
								/>
							</div>

							<Button
								onClick={saveNewOrganization}
								className={ classes.saveButton }
								variant="contained"
								color="primary"
							>
							Save
							</Button>
						</div>
					</Modal>
				</div>
			</>
		}

		<Table size="small">
			<TableHead>
				<TableRow>
					<TableCell>Organization</TableCell>
					<TableCell>Plan</TableCell>
					<TableCell>Plan Expiration</TableCell>
				</TableRow>
			</TableHead>

			<TableBody>
				{
					organizations.map( organization => {
						return <TableRow key={ organization.id } onClick={ selectOrganization } className={ classes.organizationRow } data-organization={ organization.id }>
							<TableCell>{ organization.name }</TableCell>
							<TableCell>{ organization.plan || 'Custom' }</TableCell>
							<TableCell>{ organization.planExpires && dayjs.utc( organization.planExpires ).format( 'YYYY-MM-DD' ) }</TableCell>
						</TableRow>;
					} )
				}
			</TableBody>
		</Table>

		<Pagination
			className={classes.pagination}
			count={Math.ceil( total / 20 )}
			page={pages}
			onChange={handlePagination}
		/>
	</>;
}

Organizations.propTypes = {
	classes: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
	loadOrganization: PropTypes.func.isRequired,
};

export default withStyles( styles )( Organizations );
