/**
 * External Dependencies
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Typography,
	TextField,
	Button,
} from '@material-ui/core';

import {
	Edit as EditIcon,
} from '@material-ui/icons';

/**
 * Internal Dependencies
 */

const styles = theme => ( {
	root: {
		margin: theme.spacing( 3, 0, 1 ),
	},
	button: {
		marginLeft: theme.spacing( 1 ),
	},
} );

function EditableTitle( props ) {
	const [ editing, setEditing ] = useState( false );
	const toggleEditing = () => setEditing( !editing );

	const [ revertValue, setRevertValue ] = useState( props.value );
	const [ value, setValue ] = useState( props.value );
	const onChange = event => {
		const { value } = event.target;
		setValue( value );
	};

	useEffect( () => {
		setValue( props.value );
		setRevertValue( props.value );
	}, [ props.value ] );

	const onCancel = () => {
		setValue( revertValue );
		toggleEditing();
	};

	const onSave = () => {
		props.onChange && props.onChange( value );
		setRevertValue( value );
		toggleEditing();
	};

	return <div className={props.classes.root}>
		{ editing
			? <>
				<TextField value={value} onChange={onChange} variant="outlined" size="small" />
				<Button className={props.classes.button} onClick={onCancel} variant="contained">Cancel</Button>
				<Button className={props.classes.button} onClick={onSave} color="secondary" variant="contained">Save</Button>
			</>
			: <Typography component="h1" variant="h1">
				{value} <Button className={props.classes.button} onClick={toggleEditing}><EditIcon /> Edit</Button>
			</Typography>
		}
	</div>;
}

EditableTitle.propTypes = {
	classes: PropTypes.object.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func,
};

export default withStyles( styles )( EditableTitle );
