/**
 * External Dependencies
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

/**
 * Internal Dependencies
 */
import reportWebVitals from './reportWebVitals';
import App from './App';
import theme from './theme';
import './style.css';

const { RECAPTCHA_KEY } = require( './config.json' );

ReactDOM.render(
	<Boot />,
	document.getElementById( 'root' ),
);

function Boot() {
	return <GoogleReCaptchaProvider reCaptchaKey={ RECAPTCHA_KEY }>
		<ThemeProvider theme={ theme }>
			<CssBaseline />
			<App />
		</ThemeProvider>
	</GoogleReCaptchaProvider>;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
