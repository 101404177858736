/**
 * External Dependencies
 */
import React, { useEffect, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Button,
	TextField,
	Typography,
} from '@material-ui/core';

/**
 * Internal Dependencies
 */

const styles = theme => ( {
	textField: {
		marginTop: theme.spacing( 1 ),
	},
	button: {
		marginTop: theme.spacing( 3 ),
	},
	title: {
		marginBottom: 0,
		textAlign: 'start',
	},
	recaptcha: {
		fontSize: '0.7rem',
		color: '#555',
	},
} );

function SignupForm( props ) {
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Create an event handler so you can call the verification on button click event or form submit
	const handleReCaptchaVerify = useCallback( async () => {
		if ( !executeRecaptcha ) {
			console.log( 'Execute recaptcha not yet available' );
			return;
		}

		try {
			const token = await executeRecaptcha( 'signup' );
			props.setRecaptchaToken( token );
		} catch ( _ ) {}
	}, [ executeRecaptcha ] );

	// You can use useEffect to trigger the verification as soon as the component being loaded
	useEffect( () => {
		handleReCaptchaVerify();
	}, [ handleReCaptchaVerify ] );

	const { classes } = props;
	return <form onSubmit={ props.onSubmit }>
		<Typography component="h2" variant="h4" className={ classes.title }>
			Signup
		</Typography>

		<TextField inputRef={ props.userNameRef } className={ classes.textField } label="Full Name" fullWidth />
		<TextField inputRef={ props.orgNameRef } className={ classes.textField } label="Organization Name" fullWidth />
		<TextField inputRef={ props.emailRef } className={ classes.textField } label="Email Address" fullWidth />
		<Button className={ classes.button } type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
		<p className={ classes.recaptcha }>
			This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
		</p>
	</form>;
}

SignupForm.propTypes = {
	classes: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	userNameRef: PropTypes.func.isRequired,
	orgNameRef: PropTypes.func.isRequired,
	emailRef: PropTypes.func.isRequired,
	setRecaptchaToken: PropTypes.func.isRequired,
};

export default withStyles( styles )( SignupForm );
