import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	Button,
	Menu,
	MenuItem,
} from '@material-ui/core';
import {
	Assessment as ReportIcon,
} from '@material-ui/icons';

export default function ReportsMenu( { roleId } ) {
	const [ menuAnchor, setMenuAnchor ] = useState();
	const handleMenuClick = ( event ) => {
		event.preventDefault();
		setMenuAnchor( event.currentTarget );
	};
	const handleMenuClose = () => {
		setMenuAnchor( null );
	};

	return <>
		<Button
			aria-controls="simple-menu"
			aria-haspopup="true"
			onClick={handleMenuClick}
			variant="contained"
			color="primary"
		>
			<ReportIcon /> Reports
		</Button>
		<Menu
			id="simple-menu"
			anchorEl={menuAnchor}
			open={Boolean( menuAnchor )}
			onClick={handleMenuClose}
			onClose={handleMenuClose}
		>
			<MenuItem to={`/roles/${roleId}/reports/summary`} component={Link}>Summary</MenuItem>
			<MenuItem to={`/roles/${roleId}/reports/employees`} component={Link}>Employees</MenuItem>
			<MenuItem to={`/roles/${roleId}/reports/pdvs`} component={Link}>PDV</MenuItem>
		</Menu>
	</>;
}

ReportsMenu.propTypes = {
	roleId: PropTypes.string.isRequired,
};
