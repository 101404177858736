/**
 * External Dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import {
	TextField,
	InputAdornment,
} from '@material-ui/core';

/**
 * Internal Dependencies
 */
import NumberFormattedInput from './NumberFormattedInput';

function NumberInput( props ) {
	return <NumberFormattedInput
		{...props}
	/>;
}

function DecimalInput( props ) {
	return <NumberFormattedInput
		decimalScale={2}
		fixedDecimalScale={2}
		{...props}
	/>;
}

export default function PercentInput( props ) {
	const clonedProps = Object.assign( {}, props );
	delete clonedProps.decimalInput;

	return <>
		<TextField
			InputProps={{
				inputComponent: props.decimalInput ? DecimalInput : NumberInput,
				endAdornment: <InputAdornment position="end">%</InputAdornment>,
			}}

			{...clonedProps}
		/>
	</>;
}

PercentInput.propTypes = {
	decimalInput: PropTypes.bool,
};
