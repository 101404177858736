/**
 * External Dependencies
 */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import PapaParse from 'papaparse';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	cursor: 'pointer',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

export default function DragAndDropCSV( props ) {
	const onDrop = useCallback( ( acceptedFiles ) => {
		acceptedFiles.forEach( ( file ) => {
			const fileInfo = {
				name: file.name,
				size: file.size,
				type: file.type,
			};

			switch ( fileInfo.type ) {
			case 'text/csv':
			case 'text/plain':
				break;
			default:
				props.onError( 'Invalid file type' );
				return;
			}

			if ( fileInfo.size > 256 * 1024 ) {
				props.onError( 'File size is bigger than 256kb' );
				return;
			}

			const parserOptions = {
				header: true,
				dynamicTyping: true,
				skipEmptyLines: true,
				transformHeader: header =>
					header.replace( /([-_][a-z])/ig, ( $1 ) => {
						return $1.toUpperCase()
							.replace( '-', '' )
							.replace( '_', '' );
					} ),
			};

			const reader = new FileReader();

			reader.onabort = () => console.log( 'file reading was aborted' );
			reader.onerror = () => console.log( 'file reading has failed' );
			reader.onload = () => {
				const csvData = PapaParse.parse(
					reader.result,
					Object.assign( parserOptions, {
						error: props.onError,
						encoding: 'UTF-8',
					} ),
				);

				props.onFileLoaded( csvData?.data ?? [], fileInfo );
			};

			reader.readAsText( file, 'UTF-8' );
		} );
	}, [] );

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone( { onDrop, accept: [ 'application/vnd.ms-excel', 'text/csv', 'text/plain' ] } );

	const style = useMemo( () => ( {
		...baseStyle,
		...( isDragActive ? activeStyle : {} ),
		...( isDragAccept ? acceptStyle : {} ),
		...( isDragReject ? rejectStyle : {} ),
	} ), [
		isDragActive,
		isDragReject,
		isDragAccept,
	] );

	return (
		<div className="container">
			<div {...getRootProps( { style } )}>
				<input {...getInputProps()} />
				<p>Drag and drop some files here, or click to select files</p>
			</div>
		</div>
	);
}

DragAndDropCSV.propTypes = {
	onFileLoaded: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
};
