export function calculateEmployeeSalary( salary, role, features ) {
	let newSalary = unadjustedEmployeeSalary( salary, role, features );

	const holdHarmlessSalary = ( role.holdHarmless / 100 ) * salary;
	newSalary = Math.max( holdHarmlessSalary, newSalary );

	const maxIncreaseSalary = ( role.maxIncrease / 100 ) * salary;
	newSalary = Math.min( maxIncreaseSalary, newSalary );

	// Enforce max salary
	newSalary = Math.min( role.maxSalary, newSalary );

	// Round to the nearest 3 decimals
	return Math.round( newSalary * 1000 ) / 1000;
}

export function unadjustedEmployeeSalary( salary, role, features ) {
	let newSalary = role.baseSalary;

	// Add PDVs
	for ( const feature of features ) {
		newSalary += feature.value;
	}

	return newSalary;
}
