/**
 * External Dependencies
 */
import React from 'react';
import {
	TextField,
	InputAdornment,
} from '@material-ui/core';

/**
 * Internal Dependencies
 */
import NumberFormattedInput from './NumberFormattedInput';

function NumberInput( props ) {
	return <NumberFormattedInput
		decimalScale={2}
		fixedDecimalScale={2}
		{...props}
	/>;
}

export default function DollarInput( props ) {
	return <>
		<TextField
			InputProps={{
				inputComponent: NumberInput,
				startAdornment: <InputAdornment position="start">$</InputAdornment>,
			}}

			{...props}
		/>
	</>;
}
