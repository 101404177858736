/**
 * External Dependencies
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Modal,
	Fab,
	Typography,
	LinearProgress,
} from '@material-ui/core';
import {
	CloudUpload as CloudUploadIcon,
} from '@material-ui/icons';

/**
 * Internal Dependencies
 */
import api from '../lib/api';
import DragAndDropCSV from './DragAndDropCSV';

const styles = theme => ( {
	fab: {
		margin: theme.spacing( 1 ),
	},
	extendedIcon: {
		marginRight: theme.spacing( 1 ),
	},
	modal: {
		display: 'flex',
		padding: theme.spacing( 1 ),
		alignItems: 'center',
		justifyContent: 'center',

		'& .MuiTextField-root': {
			margin: theme.spacing( 1 ),
		},
	},
	paper: {
		width: '75%',
		maxWidth: '800px',
		backgroundColor: theme.palette.background.paper,
		border: '3px solid #000',
		borderColor: theme.palette.primary.main,
		boxShadow: theme.shadows[5],
		padding: theme.spacing( 2, 4, 3 ),
	},
	downloadInstruction: {
		color: theme.palette.primary.note,
		fontSize: '0.9rem',
		margin: theme.spacing( 2, 0, 0 ),
		fontStyle: 'italic',
	},
	csvFieldName: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.default,
		padding: '2px 4px',
		borderRadius: '3px',
	},
} );

function EmployeeCSVImportButton( { classes, organization, roleId } ) {
	const [ open, setOpen ] = useState( false );
	const [ employeeErrors, setEmployeeErrors ] = useState( [] );
	const [ importErrors, setImportErrors ] = useState( [] );
	const [ progress, setProgress ] = useState( 0 );

	const downloadTxtFile = () => {
		const toCSV = ( fields ) => {
			return fields
				.map( field => `"${field}"` )
				.join( ',' );
		};

		const data = [
			toCSV( [ 'name', 'employee id', 'salary' ] ),
			toCSV( [ 'John Doe', 123, 60000 ] ),
		].join( '\n' );

		const file = new Blob( [ data ], { type: 'text/csv' } );

		const element = document.createElement( 'a' );
		element.href = URL.createObjectURL( file );
		element.download = `dinamico-export-${Date.now()}.csv`;

		document.body.appendChild( element );
		element.click();
	};

	const handleUploadOpen = ( event ) => {
		event.preventDefault();
		setImportErrors( [] );
		setEmployeeErrors( [] );
		setProgress( 0 );
		setOpen( true );
	};

	const handleUploadClose = () => {
		setOpen( false );
		setImportErrors( [] );
		setEmployeeErrors( [] );
		setProgress( 0 );
	};

	const handleCSVUpload = async ( data, { type, size, name } ) => {
		// Clear errors for repeat imports
		setImportErrors( [] );
		setEmployeeErrors( [] );

		if ( data.length > 1000 ) {
			handleCSVUploadError( 'There are more than 1000 employees in this import' );
			return;
		}

		const errors = [];
		for ( const [ i, employee ] of data.entries() ) {
			employee.roleId = roleId;

			if ( employee['employee id'] ) {
				employee.uuid = employee['employee id'];
			}

			const response = await api( `/organizations/${organization.id}/employees`, 'POST', employee );
			if ( response.ok ) {
				const body = await response.json();
				employee.uuid = body.uuid;
			} else {
				const body = await response.json();
				employee.error = body;
				errors.push( employee );
			}

			setProgress( ( ( i + 1 ) / data.length ) * 100 );
		}

		setEmployeeErrors( errors );

		if ( errors.length === 0 ) {
			handleUploadClose();
		}
	};

	const handleCSVUploadError = ( error ) => {
		setImportErrors( [ error ] );
	};

	return <>
		<Fab
			onClick={handleUploadOpen}
			variant="extended"
			color="secondary"
			aria-label="add"
			className={ classes.fab }
		>
			<CloudUploadIcon className={ classes.extendedIcon } /> Import
		</Fab>
		<Modal
			open={open}
			onClose={handleUploadClose}
			className={classes.modal}
		>
			<div className={classes.paper}>
				<Typography component="h2" variant="h2">Import Employees</Typography>
				<DragAndDropCSV
					onFileLoaded={handleCSVUpload}
					onError={handleCSVUploadError}
				/>
				<LinearProgress variant="determinate" value={progress} />

				<Typography className={ classes.downloadInstruction}>
					Upload a CSV with up to 1000 employees. The file must contain <code className={classes.csvFieldName}>name</code> and <code className={classes.csvFieldName}>salary</code> fields. You can optionally include an <code className={classes.csvFieldName}>employee id</code> field as well. Otherwise a unique employee ID will be generated for you.
				</Typography>
				<Typography className={ classes.downloadInstruction}>
					You can download a template <a href="#" onClick={downloadTxtFile}>here</a>.
				</Typography>

				{
					importErrors.length > 0 &&
						<>
							<Typography component="h3" variant="h4">Import Errors</Typography>
							<ul>
								{ importErrors.map( ( error, index ) => {
									return <li key={index}>
										{ error }
									</li>;
								} ) }
							</ul>
						</>
				}

				{ employeeErrors.length > 0 &&
					<>
						<Typography component="h3" variant="h4">Import Errors</Typography>

						<Typography>There were some problems importing the following employees</Typography>

						<ul>
							{ employeeErrors.map( ( employee, index ) => {
								return <li key={index}>
									{ JSON.stringify( employee ) }
								</li>;
							} ) }
						</ul>
					</>
				}
			</div>
		</Modal>
	</>;
}

EmployeeCSVImportButton.propTypes = {
	classes: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
	roleId: PropTypes.string.isRequired,
};

export default withStyles( styles )( EmployeeCSVImportButton );
