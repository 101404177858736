/**
 * External Dependencies
 */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
	FormControl,
	Select,
	InputBase,
	MenuItem,
} from '@material-ui/core';

/**
 * Internal Dependencies
 */
import api from '../lib/api';

const styles = theme => ( {
	root: {
		display: 'inline',
	},
} );

const Dropdown = withStyles( theme => ( {
	root: {
		'label + &': {
			marginTop: theme.spacing( 3 ),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create( [ 'border-color', 'box-shadow' ] ),
		width: 188,
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
} ) )( InputBase );

function RoleDropdown( props ) {
	const { classes, organization } = props;
	const [ roles, setRoles ] = useState( [] );
	const [ selectedRole, setSelectedRole ] = useState( null );
	const navigate = useNavigate();

	useEffect( () => {
		api( `/organizations/${organization.id}/roles?number=100` )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( roles => {
				if ( !roles ) {
					return;
				}

				setRoles( roles.data );
			} );
	}, [] );

	const changeRole = event => {
		let { value } = event.target;
		if ( value === 'all' ) {
			value = null;
		}

		setSelectedRole( value );

		props.onChange && props.onChange( value );
		navigate( {
			search: value && `?roleId=${value}`,
		} );
	};

	const params = new URLSearchParams( window.location.search );

	return <div className={classes.root}>
		<FormControl>
			<Select
				id="role-dropdown"
				value={selectedRole || params.get( 'roleId' ) || 'all'}
				onChange={changeRole}
				input={<Dropdown />}
			>
				<MenuItem value='all'>{ props.defaultValue || 'All' }</MenuItem>
				{ roles.map( role => {
					return <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>;
				} ) }
			</Select>
		</FormControl>
	</div>;
}

RoleDropdown.propTypes = {
	classes: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	defaultValue: PropTypes.string,
};

export default withStyles( styles )( RoleDropdown );
