/**
 * External Dependencies
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';

/**
 * Internal Dependencies
 */

const styles = theme => ( {
	root: {
		cursor: 'pointer',
		'&:hover': {
			color: 'red',
		},
	},
	delete: {
		color: 'red',
	},
} );

function ConfirmationDialog( props ) {
	const [ open, setOpen ] = useState( false );

	const handleClickOpen = () => {
		setOpen( true );
	};

	const handleClose = () => {
		setOpen( false );
	};

	const handleAction = () => {
		props.action();
		handleClose();
	};

	return <>
		<props.component className={props.classes.root} onClick={handleClickOpen} />
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{ props.title }
			</DialogTitle>
			{ props.description &&
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{ props.description }
					</DialogContentText>
				</DialogContent>
			}
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button className={props.classes.delete} onClick={handleAction} autoFocus>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	</>;
}

ConfirmationDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	component: PropTypes.elementType.isRequired,
	action: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
};

export default withStyles( styles )( ConfirmationDialog );
