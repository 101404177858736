/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Typography,

	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,

	Modal,
	Fab,
	Button,

	TextField,

	Snackbar,
	Slide,
} from '@material-ui/core';

import {
	Alert,
	Pagination,
} from '@material-ui/lab';

import {
	Add as AddIcon,
} from '@material-ui/icons';

/**
 * Internal Dependencies
 */
import api, { currentUserCan } from '../lib/api';

const styles = theme => ( {
	fab: {
		position: 'absolute',
		top: theme.spacing( 9 ),
		right: theme.spacing( 2 ),

		'& > *': {
			margin: theme.spacing( 1 ),
		},
	},
	modal: {
		display: 'flex',
		padding: theme.spacing( 1 ),
		alignItems: 'center',
		justifyContent: 'center',

		'& .MuiTextField-root': {
			margin: theme.spacing( 1 ),
		},
	},
	paper: {
		width: '75%',
		maxWidth: '800px',
		backgroundColor: theme.palette.background.paper,
		border: '3px solid #000',
		borderColor: theme.palette.primary.main,
		boxShadow: theme.shadows[5],
		padding: theme.spacing( 2, 4, 3 ),
	},
	saveButton: {
		margin: theme.spacing( 2, 1 ),
	},
	pagination: {
		margin: theme.spacing( 2, 1, 4 ),
	},

	noTwofactor: {
		color: theme.palette.error.main,
	},
} );

class Users extends Component {
	state = {
		error: '',
		showerror: false,

		addUserModalIsOpen: false,
		newUser: {},

		users: [],

		totalUsers: 0,
		userPage: 1,
	};

	componentDidMount() {
		this.loadUsers();
	}

	componentDidUpdate( prevProps ) {
		if ( prevProps.organization.id !== this.props.organization.id ) {
			this.loadUsers();
		}
	}

	loadUsers( page = 1 ) {
		api( `/organizations/${this.props.organization.id}/users?number=20&page=${page}` )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( users => {
				if ( !users || !users.data ) {
					return;
				}

				this.setState( { userPage: page, users: users.data, totalUsers: users.total } );
			} );
	}

	handlePagination = ( event, page ) => {
		event.preventDefault();
		this.loadUsers( page );
	};

	handleModalOpen( event ) {
		event.preventDefault();
		this.setState( { addUserModalIsOpen: true } );
	}

	handleModalClose() {
		this.setState( { newUser: {}, addUserModalIsOpen: false } );
	}

	handleNewUserChanges( field ) {
		return event => {
			const value = event.target.value;

			this.setState( state => {
				const newUser = {
					...state.newUser,
					[field]: value,
				};

				return { newUser };
			} );
		};
	}

	handleSaveNewUser() {
		const newUser = {
			...this.state.newUser,
			organizations: [
				this.props.organization.id,
			],
		};

		api( '/users', 'POST', newUser )
			.then( async response => {
				if ( !response.ok ) {
					const error = await response.json();
					switch ( error.code ) {
					case 'ER_DUP_ENTRY':
						this.showError( 'Email adddress already exists' );
						return;

					default:
						this.showError( error.message || 'Error saving new user' );
						return;
					}
				}

				return response.json();
			} )
			.then( user => {
				if ( !user ) {
					return;
				}

				this.loadUsers( this.state.userPage );
				this.handleModalClose();
			} )
			.catch( _ => {
				this.showError( 'Unknown error, please try again' );
			} );
	}

	showError( error ) {
		this.setState( { error, showerror: true } );
	}

	hideError() {
		this.setState( { showerror: false } );
	}

	render() {
		const { classes } = this.props;
		return <React.Fragment>
			<Snackbar
				open={this.state.showerror}
				onClose={this.hideError.bind( this )}
				TransitionComponent={Slide}
			>
				<Alert severity="error" variant="filled">
					{this.state.error}
				</Alert>
			</Snackbar>

			<Typography component="h1" variant="h1">
				Users
			</Typography>

			{ currentUserCan( 'user', 'write' ) &&
				<div className={ classes.fab }>
					<Fab color="primary" aria-label="add" onClick={this.handleModalOpen.bind( this )}>
						<AddIcon />
					</Fab>
					<Modal
						open={this.state.addUserModalIsOpen}
						onClose={this.handleModalClose.bind( this )}
						className={classes.modal}
					>
						<div className={classes.paper}>
							<Typography component="h2" variant="h2">Add User</Typography>

							<div>
								<TextField
									variant="outlined"
									size="small"
									label="Name"
									name="name"
									onChange={this.handleNewUserChanges( 'name' )}
								/>
							</div>

							<div>
								<TextField
									variant="outlined"
									size="small"
									label="Email"
									name="email"
									onChange={this.handleNewUserChanges( 'email' )}
								/>
							</div>

							<Button
								onClick={this.handleSaveNewUser.bind( this )}
								className={ classes.saveButton }
								variant="contained"
								color="primary"
							>
								Save
							</Button>
						</div>
					</Modal>
				</div>
			}

			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>2FA</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{ this.state.users.map( ( user, index ) => {
						return <TableRow key={ index }>
							<TableCell>{ user.name }</TableCell>
							<TableCell>{ user.email }</TableCell>
							{
								user.is2FA
									? <TableCell className={ classes.twofactor }>✔</TableCell>
									: <TableCell className={ classes.noTwofactor }>✖</TableCell>
							}
						</TableRow>;
					} ) }
				</TableBody>
			</Table>

			<Pagination
				className={classes.pagination}
				count={Math.ceil( this.state.totalUsers / 20 )}
				page={this.state.userPage}
				onChange={this.handlePagination}
			/>
		</React.Fragment>;
	}
}

Users.propTypes = {
	classes: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
};

export default withStyles( styles )( Users );
