/**
 * External Dependencies
 */
import React, { useState, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Button,
	TextField,
	Typography,
} from '@material-ui/core';

/**
 * Internal Dependencies
 */

// Code splitting this large library
const PasswordStrengthBar = React.lazy( () => import( 'react-password-strength-bar' ) );

const styles = theme => ( {
	textField: {
		marginTop: theme.spacing( 1 ),
	},
	button: {
		marginTop: theme.spacing( 3 ),
	},
	title: {
		marginBottom: 0,
		textAlign: 'start',
	},
	recaptcha: {
		fontSize: '0.7rem',
		color: '#555',
	},
} );

function LoginForm( props ) {
	const [ password, setPassword ] = useState( '' );
	const { classes } = props;
	const params = new URLSearchParams( window.location.search );
	const secret = params.get( 'secret' );

	const onChangePassword = e => {
		e.preventDefault();
		setPassword( e.target.value );
	};

	// Create an event handler so you can call the verification on button click event or form submit
	const { executeRecaptcha } = useGoogleReCaptcha();
	const handleReCaptchaVerify = useCallback( async () => {
		if ( !executeRecaptcha ) {
			console.log( 'Execute recaptcha not yet available' );
			return;
		}

		try {
			const token = await executeRecaptcha( 'password_reset' );
			props.setRecaptchaToken( token );
		} catch ( _ ) {}
	}, [ executeRecaptcha ] );

	// You can use useEffect to trigger the verification as soon as the component being loaded
	useEffect( () => {
		handleReCaptchaVerify();
	}, [ handleReCaptchaVerify ] );

	if ( secret ) {
		return <form onSubmit={ props.onSubmit }>
			<Typography component="h2" variant="h4" className={ classes.title }>
					Password Reset
			</Typography>

			<TextField inputRef={ props.passwordRef } onChange={ onChangePassword } type="password" className={ classes.textField } label="New Password" fullWidth />
			<PasswordStrengthBar password={ password } />
			<TextField inputRef={ props.confirmPasswordRef } type="password" className={ classes.textField } label="Confirm Password" fullWidth />
			<input ref={ props.secretRef } type="hidden" name="secret" value={ secret } />
			<Button className={ classes.button } type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
		</form>;
	} else {
		return <form onSubmit={ props.onSubmit }>
			<Typography component="h2" variant="h4" className={ classes.title }>
					Password Reset
			</Typography>

			<TextField inputRef={ props.emailRef } className={ classes.textField } label="Email" fullWidth />
			<Button className={ classes.button } type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
			<p className={ classes.recaptcha }>
				This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
			</p>
		</form>;
	}
}

LoginForm.propTypes = {
	classes: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	secretRef: PropTypes.func.isRequired,
	emailRef: PropTypes.func.isRequired,
	passwordRef: PropTypes.func.isRequired,
	confirmPasswordRef: PropTypes.func.isRequired,
	setRecaptchaToken: PropTypes.func.isRequired,
};

export default withStyles( styles )( LoginForm );
