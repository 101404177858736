/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
	Button,
	TextField,
	Typography,

	Snackbar,
	Slide,

	InputLabel,
	Select,
	MenuItem,
} from '@material-ui/core';

import {
	Alert,
} from '@material-ui/lab';

/**
 * Internal Dependencies
 */
import api, { currentUserCan, EDIT } from '../lib/api';

dayjs.extend( utc );
dayjs.extend( timezone );
dayjs.tz.setDefault( 'UTC' );

const styles = theme => ( {
	root: {},
	textField: {
		marginTop: theme.spacing( 3 ),
		width: 400,
	},
	submitButton: {
		margin: theme.spacing( 2 ),
	},

	planList: {
		'& dt:before': {
			content: '""',
			display: 'block',
		},
		'& dt:after': {
			content: '":"',
		},
		'& dt': {
			fontWeight: 'bold',
			display: 'inline',
		},
		'& dd': {
			display: 'inline',
			margin: theme.spacing( 0, 1 ),
		},
	},
} );

class Organization extends Component {
	state = {
		name: '',
		plan: '',
		planExpires: '',

		success: '',
		showSuccess: false,

		error: '',
		showError: false,
	};

	componentDidMount() {
		this.load();
	}

	componentDidUpdate( prevProps ) {
		if ( prevProps.organization.id !== this.props.organization.id ) {
			this.load();
		}
	}

	load() {
		api( `/organizations/${this.props.organization.id}` )
			.then( response => {
				if ( !response.ok ) {
					return;
				}

				return response.json();
			} )
			.then( organization => {
				if ( !organization ) {
					return;
				}

				this.setState( organization );
			} );
	}

	handleChange( name ) {
		return event => {
			this.setState( {
				[name]: event.target.value,
			} );
		};
	}

	handleSubmit( event ) {
		event.preventDefault();

		const body = {
			name: this.state.name,
		};

		body.plan = this.state.plan || null;

		if ( this.state.planExpires ) {
			body.planExpires = dayjs.utc( this.state.planExpires ).format( 'YYYY-MM-DD' );
		} else {
			body.planExpires = null;
		}

		api( `/organizations/${this.props.organization.id}`, 'PATCH', body )
			.then( response => {
				if ( response.ok ) {
					this.props.loadOrganization( this.props.organization.id );
					return;
				}

				return response.json();
			} )
			.then( error => {
				if ( error ) {
					this.showError( error.message );
					return;
				}

				this.showSuccess( 'Saved' );
			} );
	}

	showError( error ) {
		this.setState( { error, showerror: true } );
	}

	hideError() {
		this.setState( { showerror: false } );
	}

	showSuccess( success ) {
		this.setState( { success, showSuccess: true } );
	}

	hideSuccess() {
		this.setState( { showSuccess: false } );
	}

	render() {
		const { classes } = this.props;
		let plan = 'Custom';
		switch ( this.state.plan ) {
		case 'trial-10':
			plan = 'Trial';
			break;
		}

		return <React.Fragment>
			<form className={ classes.root } onSubmit={ this.handleSubmit.bind( this ) }>
				<Typography component="h1" variant="h1">
					Organization
				</Typography>

				<div>
					<TextField
						onChange={ this.handleChange( 'name' ) }
						value={ this.state.name }
						id="organizationName"
						className={ classes.textField }
						variant="outlined"
						size="small"
						label="Organization Name"
					/>
				</div>

				{ currentUserCan( 'organization', EDIT ) &&
					this.state.name &&
					<>
						<div className={classes.textField}>
							<InputLabel id="organizationPlanLabel">Plan</InputLabel>
							<Select
								labelId="organizationPlanLabel"
								id="organizationPlan"
								value={this.state.plan || ''}
								label="Plan"
								onChange={this.handleChange( 'plan' )}
								size="small"
								variant="outlined"
								displayEmpty
								fullWidth
							>
								<MenuItem value=''>Unlimited</MenuItem>
								<MenuItem value='trial-10'>Trial (10 Employees)</MenuItem>
							</Select>
						</div>

						{
							// Render after the organization is populated
							// We don't look at planExpires because not all orgs have an expiration,
							// so it could be empty.
							this.state.name &&
							<div>
								<TextField
									id="date"
									label="Plan Expires"
									type="date"
									sx={{ width: 220 }}
									InputLabelProps={{
										shrink: true,
									}}
									defaultValue={dayjs.utc( this.state.planExpires ).format( 'YYYY-MM-DD' )}
									onChange={this.handleChange( 'planExpires' )}
									className={ classes.textField }
									size="small"
									variant="outlined"
								/>
							</div>
						}
					</>
				}

				<Button type="submit" className={ classes.submitButton } variant="contained" color="primary">
					Save
				</Button>
			</form>

			<Typography component="h2" variant="h2">
				Plan
			</Typography>

			<dl className={ classes.planList }>
				<dt>Plan</dt>
				<dd>{ plan }</dd>

				{ this.state.planExpires &&
					<dt>Next Payment Due</dt>
				}
				{ this.state.planExpires &&
					<dd>{ new Date( this.state.planExpires ).toLocaleDateString( 'en-us', { timeZone: 'UTC' } ) }</dd>
				}
			</dl>

			<Snackbar
				open={this.state.showerror}
				onClose={this.hideError.bind( this )}
				TransitionComponent={Slide}
			>
				<Alert severity="error" variant="filled">
					{this.state.error}
				</Alert>
			</Snackbar>

			<Snackbar
				open={this.state.showSuccess}
				onClose={this.hideSuccess.bind( this )}
				TransitionComponent={Slide}
			>
				<Alert severity="success" variant="filled">
					{this.state.success}
				</Alert>
			</Snackbar>
		</React.Fragment>;
	}
}

Organization.propTypes = {
	classes: PropTypes.object.isRequired,
	organization: PropTypes.object.isRequired,
	loadOrganization: PropTypes.func.isRequired,
};

export default withStyles( styles )( Organization );
