/**
 * External Dependencies
 */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { isEmail } from 'validator';
import {
	Box,
	Container,
	Snackbar,
	Slide,

	Link,
} from '@material-ui/core';
import {
	Alert,
} from '@material-ui/lab';

/**
 * Internal Dependencies
 */
import Logo from '../dinamico.svg';
import api from '../lib/api';
import SignupForm from '../components/Login/SignupForm';

const styles = theme => ( {
	root: {
		maxWidth: '380px',
		textAlign: 'center',
	},
	form: {
		backgroundColor: '#fff',
		borderRadius: '5px',
		padding: theme.spacing( 3 ),
		margin: theme.spacing( 5, 0, 2, 0 ),
	},
	innerBox: {
		padding: theme.spacing( 1 ),
	},
	logo: {
		width: '100%',
	},
	textField: {
		marginTop: theme.spacing( 3 ),
	},
	footer: {
		'& span:not(:last-child)::after': {
			content: '"\\00B7"',
			padding: theme.spacing( 1 ),
		},
	},
} );

class Signup extends Component {
	state = {
		error: '',
		showerror: false,

		message: '',
		showMessage: false,

		recaptchaToken: '',
	};

	userNameRef = createRef();
	orgNameRef = createRef();
	emailRef = createRef();

	handleSignup( e ) {
		e.preventDefault();

		if ( !this.state.recaptchaToken ) {
			this.showError( 'Unknown error. Please refresh and try again.' );
			return;
		}

		if ( !this.userNameRef.value ) {
			this.showError( 'Name must be set' );
			return;
		}

		if ( !this.orgNameRef.value ) {
			this.showError( 'Organization name must be set' );
			return;
		}

		if ( !isEmail( this.emailRef.value ) ) {
			this.showError( 'Invalid email address' );
			return;
		}

		api( '/auth/signup', 'POST', {
			token: this.state.recaptchaToken,
			userName: this.userNameRef.value,
			organizationName: this.orgNameRef.value,
			email: this.emailRef.value,
		} )
			.then( async response => {
				if ( !response.ok ) {
					const body = await response.json();

					switch ( body.code ) {
					case 'ER_DUP_ENTRY':
						this.showError( 'Email adddress already exists' );
						return;

					default:
						this.showError( body.message );
						return;
					}
				}

				window.location.replace( '/' );
			} )
			.catch( _ => {
				this.showError( 'Unknown error, please try again' );
			} );
	}

	setRecaptchaToken( recaptchaToken ) {
		this.setState( { recaptchaToken } );
	}

	showError( error ) {
		this.setState( { error, showerror: true } );
	}

	hideError() {
		this.setState( { showerror: false } );
	}

	showMessage( message ) {
		this.setState( { message, showMessage: true } );
	}

	hideMessage() {
		this.setState( { showMessage: false } );
	}

	render() {
		const { classes } = this.props;
		return <Box className={ classes.innerBox }>
			<Container maxWidth="sm" elevation="16" className={ classes.root }>
				<Container maxWidth="sm" elevation="16" className={ classes.form }>
					<Snackbar
						open={this.state.showMessage}
						onClose={this.hideMessage.bind( this )}
						TransitionComponent={Slide}
					>
						<Alert severity="success" variant="filled">
							{this.state.message}
						</Alert>
					</Snackbar>
					<Snackbar
						open={this.state.showerror}
						onClose={this.hideError.bind( this )}
						TransitionComponent={Slide}
					>
						<Alert severity="error" variant="filled">
							{this.state.error}
						</Alert>
					</Snackbar>
					<img className={ classes.logo } src={Logo} />
					<SignupForm
						userNameRef={ ref => ( this.userNameRef = ref ) }
						orgNameRef={ ref => ( this.orgNameRef = ref ) }
						emailRef={ ref => ( this.emailRef = ref ) }
						setRecaptchaToken={ this.setRecaptchaToken.bind( this ) }
						onSubmit={ this.handleSignup.bind( this ) }
					/>
				</Container>
				<div className={ classes.footer }>
					<span><Link to={ '/login' } component={ RouterLink }>Login</Link></span>
					<span><Link to={ '/password-reset' } component={ RouterLink }>Forgot Password?</Link></span>
				</div>
			</Container>
		</Box>;
	}
}

Signup.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Signup );
