/**
 * External Dependencies
 */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { isEmail } from 'validator';
import {
	Box,
	Container,
	Snackbar,
	Slide,

	Link,
} from '@material-ui/core';
import {
	Alert,
} from '@material-ui/lab';

/**
 * Internal Dependencies
 */
import Logo from '../dinamico.svg';
import api from '../lib/api';
import PasswordResetForm from '../components/Login/PasswordResetForm';

const styles = theme => ( {
	root: {
		maxWidth: '380px',
		textAlign: 'center',
	},
	form: {
		backgroundColor: '#fff',
		borderRadius: '5px',
		padding: theme.spacing( 3 ),
		margin: theme.spacing( 5, 0, 2, 0 ),
	},
	innerBox: {
		padding: theme.spacing( 1 ),
	},
	logo: {
		width: '100%',
	},
	textField: {
		marginTop: theme.spacing( 3 ),
	},
	footer: {
		'& span:not(:last-child)::after': {
			content: '"\\00B7"',
			padding: theme.spacing( 1 ),
		},
	},
} );

class Login extends Component {
	state = {
		error: '',
		showerror: false,

		message: '',
		showMessage: false,

		recaptchaToken: '',
	};

	secretRef = createRef();
	emailRef = createRef();
	passwordRef = createRef();
	confirmPasswordRef = createRef();

	handleLogin( e ) {
		e.preventDefault();

		if ( this.secretRef.value ) {
			if ( !this.passwordRef.value ) {
				this.showError( 'Invalid password' );
				return;
			}

			if ( this.passwordRef.value !== this.confirmPasswordRef.value ) {
				this.showError( 'Passwords must match' );
				return;
			}

			api( '/auth/reset/new', 'POST', {
				secret: this.secretRef.value,
				password: this.passwordRef.value,
			} )
				.then( async response => {
					if ( !response.ok ) {
						const body = await response.json();
						this.showError( body.message );
						return;
					}

					window.location.replace( '/' );
				} )
				.catch( _ => {
					this.showError( 'Unknown error, please try again' );
				} );
		} else {
			if ( !this.emailRef.value || !isEmail( this.emailRef.value ) ) {
				this.showError( 'Invalid email address' );
				return;
			}

			if ( !this.state.recaptchaToken ) {
				this.showError( 'Unknown error. Please refresh and try again.' );
				return;
			}

			api( '/auth/reset', 'POST', {
				email: this.emailRef.value,
				token: this.state.recaptchaToken,
			} )
				.then( async response => {
					if ( !response.ok ) {
						const body = await response.json();
						this.showError( body.message );
						return;
					}

					this.showMessage( 'Check your email' );
				} )
				.catch( _ => {
					this.showError( 'Unknown error, please try again' );
				} );
		}
	}

	setRecaptchaToken( recaptchaToken ) {
		this.setState( { recaptchaToken } );
	}

	showError( error ) {
		this.setState( { error, showerror: true } );
	}

	hideError() {
		this.setState( { showerror: false } );
	}

	showMessage( message ) {
		this.setState( { message, showMessage: true } );
	}

	hideMessage() {
		this.setState( { showMessage: false } );
	}

	render() {
		const { classes } = this.props;
		return <Box className={ classes.innerBox }>
			<Container maxWidth="sm" elevation="16" className={ classes.root }>
				<Container maxWidth="sm" elevation="16" className={ classes.form }>
					<Snackbar
						open={this.state.showMessage}
						onClose={this.hideMessage.bind( this )}
						TransitionComponent={Slide}
					>
						<Alert severity="success" variant="filled">
							{this.state.message}
						</Alert>
					</Snackbar>
					<Snackbar
						open={this.state.showerror}
						onClose={this.hideError.bind( this )}
						TransitionComponent={Slide}
					>
						<Alert severity="error" variant="filled">
							{this.state.error}
						</Alert>
					</Snackbar>
					<img className={ classes.logo } src={Logo} />
					<PasswordResetForm
						secretRef={ ref => ( this.secretRef = ref ) }
						emailRef={ ref => ( this.emailRef = ref ) }
						passwordRef={ ref => ( this.passwordRef = ref ) }
						confirmPasswordRef={ ref => ( this.confirmPasswordRef = ref ) }
						onSubmit={ this.handleLogin.bind( this ) }
						setRecaptchaToken={ this.setRecaptchaToken.bind( this ) }
					/>
				</Container>
				<div className={ classes.footer }>
					<span><Link to={ '/signup' } component={ RouterLink }>Sign Up</Link></span>
					<span><Link to={ '/login' } component={ RouterLink }>Login</Link></span>
				</div>
			</Container>
		</Box>;
	}
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Login );
