/**
 * External Dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

export default function NumberFormattedInput( props ) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumericFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange( {
					target: {
						name: props.name,
						value: values.value,
					},
				} );
			}}
			thousandSeparator
			valueIsNumericString
		/>
	);
}

NumberFormattedInput.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};
