export function formatNumber( number, digits ) {
	number = parseFloat( number ) || 0.0;
	let [ beforeDecimal, afterDecimal ] = number.toString().split( '.' );

	if ( !afterDecimal ) {
		afterDecimal = '00';
	}

	return new Intl.NumberFormat( 'en', { minimumFractionDigits: digits ?? 0, maximumFractionDigits: digits ?? 2 } ).format( `${beforeDecimal}.${afterDecimal}` );
}

export function formatPercent( number ) {
	number = parseFloat( number ) || 0.0;
	return number * 100;
}

export function dollars( number ) {
	if ( !number ) {
		return '-';
	}

	let sign = '';
	if ( number < 0 ) {
		sign = '-';
		number = number * -1;
	}

	number = formatNumber( number, 2 );
	return `${sign}$${number}`;
}

export function percent( number ) {
	number = Math.round( formatPercent( number ) * 100 ) / 100;
	return `${number}%`;
}
