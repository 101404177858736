/**
 * External Dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Button,
	TextField,
} from '@material-ui/core';

/**
 * Internal Dependencies
 */

const styles = theme => ( {
	textField: {
		marginTop: theme.spacing( 3 ),
	},
	note: {
		color: theme.palette.grey[700],
		fontSize: '0.9em',
	},
} );

class TOTPForm extends Component {
	constructor( props ) {
		super();
		this.props = props;
	}

	render() {
		const { classes } = this.props;
		return <form onSubmit={ this.props.onSubmit }>
			<TextField inputRef={ this.props.tokenRef } className={ classes.textField } label="Token" fullWidth />
			<Button className={ classes.textField } type="submit" variant="contained" color="primary" fullWidth>Login</Button>
			<p className={ classes.note }>Enter the two factor code from your mobile device.</p>
		</form>;
	}
}

TOTPForm.propTypes = {
	classes: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	tokenRef: PropTypes.func.isRequired,
};

export default withStyles( styles )( TOTPForm );
